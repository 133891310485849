import React, { useContext, useEffect, useState } from "react";
import { Grupare, GrupareCategoryEnum } from "../models/grupare.model";
import { GetGrupari, GetGrupariSubspecializari, GetMaterii, GetPlanInvatamant, GetValidatedPlanData, HandleChangesPlanInvatamant, ValidatePlanInvatamant } from "../api/ApiService";
import { Materie } from "../models/materie.model";
import { PlanInvatamant } from "../models/plan-invatamant.model";
import { IPlanInvatamantEntryModel } from "../models/generic-plan-invatamant-entry.model";
import { NavLink, useParams } from "react-router-dom";
import { DropdownOption } from "../components/renderers/DropdownRenderer";

import { Plan } from "../components/planuri-invatamant/PlanInvatamant";
import { PlanInvatamantTableData, PlanTableType } from "../models/plan-invatamant-table-data.model";
import { PlanSummary } from "../components/planuri-invatamant/PlanSummary";
import { PermissionContext, PermissionType } from "../store/permission-context";
import { ToastAlert } from "../components/ToastAlert";
import { PlanInvatamantSummaryTableData } from "../models/plan-invatamant-summary-table-data.model";
import { MateriiTableData } from "../models/materii-table-data.model";
import { GrupareSubspecializare } from "../models/grupare-subspecializare.model";
import { PlanTableContext } from "../store/plan-table-context";
import { PlanEntryType } from "../models/plan-entry.model";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

const DEFAULT_MAX_YEARS = 4;


export const PlanInvatamantPage = () => {
  const { hasPermission } = useContext(PermissionContext);

  // const [sheetData, setSheetData] = useState<{ rows: IModel[], errors: ValidatedCellError[], modifiedEntriesIds: string[], deletedEntriesIds: string[] }>({ rows: [], errors: [], modifiedEntriesIds: [], deletedEntriesIds: [], });
  const [planInvatamant, setPlanInvatamant] = useState<PlanInvatamant>();
  const [tableData, setTableData] = useState<PlanInvatamantTableData>({ years: [], summary: [] });
  const [tableDataOptionale, setTableDataOptionale] = useState<PlanInvatamantTableData>({ years: [], summary: [] });
  const [tableDataFacultative, setTableDataFacultative] = useState<PlanInvatamantTableData>({ years: [], summary: [] });
  const [materii, setMaterii] = useState<Materie[]>([]);
  const [grupari, setGrupari] = useState<Grupare[]>([]);
  const [grupariSubspecializari, setGrupariSubspecializari] = useState<GrupareSubspecializare[]>([]);
  const [validateButtonEnabled, setValidateButtonEnabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [toastInfo, setToastInfo] = useState<{ message: string, show: boolean, type: "success" | "error" | "warning" | "info" }>({ message: '', type: 'info', show: false });

  const planInvatamantId = useParams();

  useEffect(() => {
    GetRequiredData(planInvatamantId.id || "").then(async x => {
      setPlanInvatamant(x.planInvatamant)
      var tempTableDate = await prepareTableData(x.planInvatamant);

      setTableData(tempTableDate);
      setMaterii(x.materii);
      setGrupari(x.grupari);
      setGrupariSubspecializari(x.grupariSubspecializari);
      // console.log(x.materii);
    })
  }, []);

  useEffect(() => {
    if (tableData.years && tableData.years.length > 0) {
      setLoading(false);
    }
  }, [tableData])

  const GetRequiredData = async (id: string): Promise<{ planInvatamant: PlanInvatamant, materii: Materie[], grupari: Grupare[], grupariSubspecializari: GrupareSubspecializare[] }> => {
    let planInvatamant = (await GetPlanInvatamant(id)).data;

    let materii = (await GetMaterii()).data;

    let grupari = (await GetGrupari()).data;

    let grupariSubspecializari = (await GetGrupariSubspecializari()).data;

    return { planInvatamant, materii, grupari, grupariSubspecializari }
  }

  const prepareTableData = async (planInvatamant: PlanInvatamant) => {
    var maxYear = (planInvatamant.indiciPlan.find(e => e.indice.slug === "durata-studii")?.valoare || DEFAULT_MAX_YEARS);
    var data: PlanInvatamantTableData = { years: [], summary: [] };

    for (let yearIdx = 0; yearIdx < maxYear; yearIdx++) {
      var tempIntrariAn = planInvatamant.intrari.filter(e => e.intrare.an === yearIdx + 1);
      data.years[yearIdx] = { semesters: [] };

      for (let semesterIdx = 0; semesterIdx < 2; semesterIdx++) {
        data.years[yearIdx].semesters[semesterIdx] = { intrari: [], summary: [] }
        var tempIntrariSemestru = tempIntrariAn.filter(e => e.intrare.semestru === semesterIdx + 1);

        var maxOrder = tempIntrariSemestru.length > 0 ? Math.max(...tempIntrariSemestru.map(e => e.ordine)) : 0;

        var intrariIdx = 0;
        for (let orderIdx = 0; tempIntrariSemestru.length > 0 && orderIdx <= maxOrder; orderIdx++) {
          if (tempIntrariSemestru[intrariIdx].ordine === orderIdx) {

            data.years[yearIdx].semesters[semesterIdx].intrari.push(tempIntrariSemestru[intrariIdx++]);
          }
          else {
            data.years[yearIdx].semesters[semesterIdx].intrari.push({ intrare: {} as IPlanInvatamantEntryModel, tip: "Empty" });
          }
        }
        //Daca 2 materii au acelasi order, unele vor ramane neafisate. In teorie nu au cum sa aiba acelasi order, 
        //dar daca modifica anul unei materii care este deja in plan acest lucru se poate intampla
      }
    }

    var grupariOptionale = ExtractGrupari(data, GrupareCategoryEnum.Optionale);
    var optionale = prepareMateriiTableData(grupariOptionale);
    var grupariFacultative = ExtractGrupari(data, GrupareCategoryEnum.Facultative);
    var facultative = prepareMateriiTableData(grupariFacultative);
    setTableDataOptionale(optionale);
    setTableDataFacultative(facultative);

    var entriesTableData = { years: data.years.map(y => ({ semesters: y.semesters.map(s => ({ intrari: s.intrari.filter(i => i.tip != PlanEntryType.Empty) })) })) };
    // console.log(entriesTableData);

    let planValidationResponse = (await GetValidatedPlanData(planInvatamant, entriesTableData)).data;

    if (planValidationResponse) {
      // console.log(planValidationResponse);
      var summaryTableData = planValidationResponse.item as PlanInvatamantSummaryTableData;
      data.years.forEach((year, yearIdx) => {
        year.semesters.forEach((semester, semesterIdx) => {
          // console.log(semester);
          if (summaryTableData.years &&
            summaryTableData.years[yearIdx] &&
            summaryTableData.years[yearIdx].semesters &&
            summaryTableData.years[yearIdx].semesters[semesterIdx]) {

            semester.summary = summaryTableData.years[yearIdx].semesters[semesterIdx].summary;
          }
        })
      })
      data.summary = summaryTableData.summary;
    }
    // console.log(data);
    return data;
  }

  const prepareMateriiTableData = (grupari: Grupare[]) => {
    var data: PlanInvatamantTableData = { years: [], summary: [] };
    var maxYear = Math.max(...grupari.map(g => (g.materie?.map(m => m.an) || [])).flat(1), 0);
    maxYear = maxYear === 0 ? DEFAULT_MAX_YEARS : maxYear;

    for (let yearIdx = 0; yearIdx < maxYear; yearIdx++) {
      var grupariAn = grupari.filter(e => e.an === yearIdx + 1);
      data.years[yearIdx] = { semesters: [] };
      data.years[yearIdx].semesters[0] = { intrari: [], summary: [] }
      data.years[yearIdx].semesters[1] = { intrari: [], summary: [] }

      grupariAn.forEach(grupare => {
        var intrari = grupare.materie?.filter(m => m.semestru == 1).map(m => {
          var materie = structuredClone(m);
          var existingMaterie = data.years[yearIdx].semesters[0].intrari.find(e => e.intrare.id == materie.id);

          if (existingMaterie) {
            materie.nume = existingMaterie.intrare.nume.substring(0, existingMaterie.intrare.nume.length - 1) + ", Set " + grupare.nume + ")";
          }
          else {
            materie.nume = materie.nume + " (Set " + grupare.nume + ")";
          }

          return ({ intrare: materie, tip: PlanEntryType.Materie })
        });
        data.years[yearIdx].semesters[0].intrari = data.years[yearIdx].semesters[0].intrari.concat(intrari || []);
        intrari = grupare.materie?.filter(m => m.semestru == 2).map(m => {
          var materie = structuredClone(m);
          var existingMaterie = data.years[yearIdx].semesters[1].intrari.find(e => e.intrare.id == materie.id);

          if (existingMaterie) {
            materie.nume = existingMaterie.intrare.nume.substring(0, existingMaterie.intrare.nume.length - 1) + ", Set " + grupare.nume + ")";
          }
          else {
            materie.nume = materie.nume + " (Set " + grupare.nume + ")";
          }

          return ({ intrare: materie, tip: PlanEntryType.Materie })
        });
        data.years[yearIdx].semesters[1].intrari = data.years[yearIdx].semesters[1].intrari.concat(intrari || []);
      });
    }


    return data;
  }

  const ExtractGrupari = (data: PlanInvatamantTableData, category: GrupareCategoryEnum) => {
    var grupari: Grupare[] = [];

    data.years.forEach(year => {
      year.semesters.forEach(semester => {
        semester.intrari.forEach(intrare => {
          if (intrare.tip === PlanEntryType.Grupare) {
            var tempGrupare = intrare.intrare as Grupare;
            if (tempGrupare.category == category && !grupari.find(g => g.nume == tempGrupare.nume)) {
              grupari.push(tempGrupare);
            }
          }
          else if (category == GrupareCategoryEnum.Optionale && intrare.tip === PlanEntryType.GrupareSubspecializazre) {
            var tempGrupareSubspecializare = intrare.intrare as GrupareSubspecializare;
            tempGrupareSubspecializare.grupare?.forEach(gr => {
              if (!grupari.find(g => g.nume == gr.nume)) {
                grupari.push(gr);
              }
            });
          }
        })
      })
    });

    return grupari;
  }

  const getCombinedTableData = () => {
    var intrari: { intrare: IPlanInvatamantEntryModel, tip: string, ordine: number }[] = [];
    var ordine = 0;
    tableData.years.forEach(year => {
      year.semesters.forEach(semester => {
        ordine = 0;
        semester.intrari.forEach(intrare => {
          if (intrare.tip !== "Empty") {
            intrari.push({ intrare: intrare.intrare, tip: intrare.tip, ordine: ordine });
          }
          ordine++;
        })

        ordine = intrari.length > 0 ? intrari[intrari.length - 1].ordine + 1 : ordine; //Reseteaza ordinea la maximul de pana acum, in cazul in care au fost celule goale dupa ultima intrare dintr-un an
      })
    });

    return intrari;
  }

  const handleValidate = () => {
    ValidatePlanInvatamant(planInvatamant?.id || "", tableData).then(r => {
      setToastInfo({ message: 'Planul a fost validat cu succes.', show: true, type: 'success' });
    })
      .catch(e => setToastInfo({ message: 'Ceva nu a mers bine. Reincearca mai tarziu.', show: true, type: 'error' }));
  }

  const handleSave = () => {
    var newPlanInvatamant = { ...planInvatamant };

    newPlanInvatamant.intrari = getCombinedTableData();

    // console.log(newPlanInvatamant);

    HandleChangesPlanInvatamant([newPlanInvatamant as PlanInvatamant], [], 'plan-invatamant')
      .then(r => {
        setToastInfo({ message: 'Datele au fost salvate cu succes.', show: true, type: 'success' });
        setValidateButtonEnabled(true);
      })
      .catch(e => {
        setToastInfo({ message: 'Ceva nu a mers bine. Reincearca mai tarziu.', show: true, type: 'error' });
        setValidateButtonEnabled(false);
      });
  }

  const handleChange = async (selected: DropdownOption | null, yearIdx: number, semesterIdx: number, idx: number) => {
    let newTableData: PlanInvatamantTableData;
    setTableData(prevTableData => {
      newTableData = { ...prevTableData };
      if (selected) {
        if (newTableData.years[yearIdx].semesters[semesterIdx]?.intrari) {
          let selectedEntity = materii.find(i => i.id === selected.value)
          let tip = PlanEntryType.Materie;
          if (!selectedEntity) {
            selectedEntity = grupari.find(i => i.id === selected.value);
            if (selectedEntity) {
              tip = PlanEntryType.Grupare;
            }
            else if (!selectedEntity) {
              selectedEntity = grupariSubspecializari.find(i => i.id === selected.value);
              if (selectedEntity) {
                tip = PlanEntryType.GrupareSubspecializazre;
              }
            }
          }

          if (selectedEntity) {
            newTableData.years[yearIdx].semesters[semesterIdx].intrari[idx] = {
              ...newTableData.years[yearIdx].semesters[semesterIdx].intrari[idx],
              tip: tip,
              intrare: selectedEntity
            };
          }
        }
      } else {
        if (newTableData.years[yearIdx].semesters[semesterIdx]?.intrari) {
          newTableData.years[yearIdx].semesters[semesterIdx].intrari[idx] = {
            ...newTableData.years[yearIdx].semesters[semesterIdx].intrari[idx],
            tip: "Empty",
            intrare: {} as IPlanInvatamantEntryModel
          };
        }
      }

      return newTableData;
    });

    var newEntriesTableData = {years: newTableData.years.map(y => ({semesters: y.semesters.map(s => ({intrari: s.intrari.filter(i => i.tip != PlanEntryType.Empty)}))}))};

    let planValidationResponse = (await GetValidatedPlanData(planInvatamant, newEntriesTableData)).data;
    if (planValidationResponse) {
      setTableData(prevTableData => {
        const updatedTableData = { ...prevTableData };
        const summary = planValidationResponse.item;
        updatedTableData.years.forEach((year, yearIdx) => {
          year.semesters.forEach((semester, semesterIdx) => {
            semester.summary = summary.years[yearIdx].semesters[semesterIdx].summary;
          });
        });
        updatedTableData.summary = summary.summary;
        return updatedTableData;
      });
    }

    var grupariOptionale = ExtractGrupari(newTableData, GrupareCategoryEnum.Optionale);
    var optionale = prepareMateriiTableData(grupariOptionale);
    var grupariFacultative = ExtractGrupari(newTableData, GrupareCategoryEnum.Facultative);
    var facultative = prepareMateriiTableData(grupariFacultative);
    setTableDataOptionale(optionale);
    setTableDataFacultative(facultative);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return <div className="w-100 pt-20 flex flex-col min-h-screen">
    <div className="group relative">
      <div className="w-100 flex justify-between align-center mt-5 mb-2">
        <h1 className="text-5xl font-semibold upt-text-dark">Plan învățământ</h1>
      </div>
      <div className="mb-5">
        <h2 className="font-semibold">{planInvatamant?.cod ? `${planInvatamant.cod}` : ""}</h2>
      </div>
      <span className="absolute bottom-30 scale-0 transition-all rounded bg-gray-400 p-2 text-white group-hover:scale-100 z-10">
        <p><b>Programul de studii - {planInvatamant?.specializare?.cicluStudiu?.nume}: </b>{planInvatamant?.specializare?.nume}</p>
        <p><b>Domeniul fundamental (DFI):</b> {planInvatamant?.specializare?.domeniu?.ramuraStiinta?.domeniuFundamental?.nume}</p>
        <p><b>Ramura de știință (RSI):</b> {planInvatamant?.specializare?.domeniu?.ramuraStiinta?.nume}</p>
        { planInvatamant?.specializare?.cicluStudiu?.cod.toUpperCase() === "L" ?
          <p><b>Domeniul de licență (DL):</b> {planInvatamant?.specializare?.domeniu?.numeDl}</p>
          : <p><b>Domeniul de studii universitare (DSU):</b> {planInvatamant?.specializare?.domeniu?.numeDsu}</p>
        }
        <p><b>Durata studiilor / Numărul de credite:</b> {planInvatamant?.anFinal - planInvatamant?.anInceput} / {planInvatamant?.nrCredite}</p>
        <p><b>Forma de învățământ:</b> TODO</p>
      </span>
    </div>

    {hasPermission(PermissionType.IndicePlanRead) && <div className="flex w-1o0 justify-end py-10">
      <NavLink to={'indicatori'}>
        <button disabled={planInvatamant ? false : true} className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
          Indicatori
        </button>
      </NavLink>
    </div>}

    <div className="w-full flex flex-col">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
            expandIcon={<div>&#9660;</div>}
            aria-controls="panel2-content"
            id="panel2-header"
        >
          <div className="w-100 flex justify-between align-center my-5">
            <h3 className="text-3xl font-semibold upt-text-dark">Ani studiu</h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <PlanTableContext.Provider value={{ tableType: PlanTableType.IntrariPlan }}>
            <Plan
                planData={tableData}
                materii={materii}
                grupari={grupari}
                handleChange={(selected, an, semesterIdx, idx) => handleChange(selected, an, semesterIdx, idx)}
            />
          </PlanTableContext.Provider>
        </AccordionDetails>
      </Accordion>

        <Accordion>
          <AccordionSummary
              expandIcon={<div>&#9660;</div>}
              aria-controls="panel3-content"
              id="panel3-header"
          >
            <div className="w-100 flex justify-between align-center my-5">
              <h3 className="text-3xl font-semibold upt-text-dark">
                Discipline optionale
              </h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <PlanTableContext.Provider value={{ tableType: PlanTableType.Optionale }}>
              <Plan
                  planData={tableDataOptionale}
                  materii={materii}
                  grupari={grupari}
                  // handleChange={(selected, an, semesterIdx, idx) => handleChange(selected, an, semesterIdx, idx)}
              />
            </PlanTableContext.Provider>
          </AccordionDetails>
        </Accordion>
      <Accordion>
        <AccordionSummary
            expandIcon={<div>&#9660;</div>}
            aria-controls="panel4-content"
            id="panel4-header"
        >

          <div className="w-100 flex justify-between align-center my-5">
            <h3 className="text-3xl font-semibold upt-text-dark">
              Discipline facultative
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <PlanTableContext.Provider value={{ tableType: PlanTableType.Facultative }}>
            <Plan
                planData={tableDataFacultative}
                materii={materii}
                grupari={grupari}
                // handleChange={(selected, an, semesterIdx, idx) => handleChange(selected, an, semesterIdx, idx)}
            />
          </PlanTableContext.Provider>
        </AccordionDetails>
      </Accordion>



      {planInvatamant ? <PlanSummary planData={tableData} /> : <></>}

      <div className="flex w-1o0 justify-end py-10">
        {hasPermission(PermissionType.PlanInvatamantValidate) && <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" disabled={!validateButtonEnabled} onClick={() => handleValidate()}>
          Validează
        </button>}
        {hasPermission(PermissionType.PlanInvatamantWrite) && <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 ml-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
          Salvează
        </button>}
      </div>
    </div>

    {/* {
      sheetData.errors.length > 0 ? <div className="bg-rose-100 border-t-4 rounded-b text-rose-900 px-4 py-3 shadow-md mb-4" role="alert">
        <div className="flex">
          <div className="py-1"><svg className="fill-current h-6 w-6 text-rose-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
          <div>
            <p className="font-bold">Au fost găsite erori în următoarele schimbări</p>
            {sheetData.errors.map(e => <p className="text-sm">Coloana {camelToFlat(e.rule.fieldName)}, rândul {sheetData.rows.findIndex(r => r.id === e.rowId) + 1}, cu valoarea "{e.value}" are eroarea: {e.rule.errorMessage}.</p>)}
          </div>
        </div>
      </div> : <></>
    } */}
    {toastInfo.show ? <ToastAlert message={toastInfo.message} type={toastInfo.type} onClose={() => setToastInfo({ ...toastInfo, show: false })} /> : <></>}

  </div >;
}



