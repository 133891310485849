import { Domeniu } from "./domeniu.model";
import { IModel } from "./generic.model";
import { Specializare } from "./specializare.model";

export interface Materie extends IModel {
    domId: string,
    specId: string,
    cod: string,
    prefix: string,
    an: number,
    semestru: number,
    category: MaterieCategoryEnum,
    oreCurs: number,
    oreSeminar: number,
    oreLaborator: number,
    oreProiect: number,
    orePractica: number,
    oreIndividuale: number,
    nrCredite: number,
    formaEvaluare: FormaEvaluareEnum,
    categorieFormativa: CategorieFormativaEnum,
    nume: string,
    domeniu?: Domeniu,
    specializare?: Specializare,

    validated: ValidatedEnum
}

export enum CategorieFormativaEnum {
    DF = "DF",
    DD = "DD",
    DS = "DS",
    DC = "DC",
    f = "f" 
}

export enum MaterieCategoryEnum {
    Practica = "Practica",
    ElaborareProiectDiploma = "ElaborareProiectDiploma",
    ExamenDiploma = "ExamenDiploma",
    EducatieFizica = "EducatieFizica"
}

export enum FormaEvaluareEnum {
    E = "E",
    D = "D",
    C = "C"
}

export enum ValidatedEnum {
    Nevalidat = "Nevalidat",
    Validat = "Validat"
}


export enum DisplayCategorieFormativaEnum {
    DF = "DF",
    DD = "DD",
    DS = "DS",
    DC = "DC",
    f = "f" 
}

export enum DisplayMaterieCategoryEnum {
    Practica = "Practică",
    ElaborareProiectDiploma = "Elaborare Proiect Diplomă",
    ExamenDiploma = "Examen Diplomă",
    EducatieFizica = "Educație Fizică",
}

export enum DisplayFormaEvaluareEnum {
    E = "E",
    D = "D",
    C = "C"
}

export enum DisplayValidatedEnum {
    Nevalidat = "Nevalidat",
    Validat = "Validat"
}

