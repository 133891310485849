import 'react-data-grid/lib/styles.css';
import { ValidatedCellError } from "../models/validated-cell-error.model";
import { Key, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IModel } from "../models/generic.model";
import { ValidationRule } from "../models/validation-rule.model";
import { camelToFlat, Comparator } from "../services/utils.service";
import DataGrid, { CellSelectArgs, Column, CopyEvent, DataGridHandle, FillEvent, RowsChangeData, SortColumn } from 'react-data-grid';
import '../styles/grid-styles.scss';
import { ToastAlert } from '../components/ToastAlert';
import { PermissionContext, PermissionType } from '../store/permission-context';
import {MultiselectDropdown} from "../components/MultiselectDropdown";



type SheetPageProps = {
  sheetColumns: Column<IModel>[],
  title: string;
  initialEntries: IModel[],
  validationRules: ValidationRule[],
  defaultObject: IModel,
  noEmptyRow: boolean,
  requiredEditPermission: PermissionType,
  hasEmptyProperties: (item: IModel) => boolean,
  onSave: (modifiedEntries: IModel[], deletedEntries: string[]) => Promise<any>,
  rowsToString: (rows: IModel[], columnsOrder: readonly number[]) => string,
  stringToRows: (splitText: string[][], columnsOrder: readonly number[]) => IModel[],
  addPastedRows: (currentRows: IModel[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]) => { rows: IModel[], indexes: number[] },
  deleteCell: (currentRows: IModel[], rowIdx: number, columnKey: string) => { rows: IModel[], deleted: boolean },
  updateColumns: (currentRows: IModel[], columns: Column<IModel>[]) => Column<IModel>[],
  getComparator: (sortColumn: string) => Comparator,
  refreshTable: () => void,
}

const hasChanged = (item: IModel, items: IModel[]) => {

  let initialVersion = items.find(i => i.id === item.id);
  if (initialVersion) {
    return Object.entries(item).toString() !== Object.entries(initialVersion).toString();
  }

  return true;
}

function getComparator(sortColumn: string): Comparator {
  // case 'available':  //checkbox
  // return (a, b) => {
  //   return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
  // };
  return (a, b) => {
    return (a[sortColumn] + '').localeCompare(b[sortColumn] + '');
  };

}

const GetRowErrors = (rowId: string, row: IModel, validationRules: ValidationRule[]): ValidatedCellError[] => {
  let errors: ValidatedCellError[] = [];
  validationRules.forEach(rule => {
    var fieldName = rule.fieldName;
    if (rule.isMandatory) {
      if (row[fieldName] === '' || row[fieldName] === null || row[fieldName] === undefined) {
        errors.push({ rule: rule, value: row[fieldName], rowId: rowId, column: fieldName });
      } else if (rule.regexPattern) {
        let regex = new RegExp(rule.regexPattern);
        if (!regex.test(row[fieldName])) {
          errors.push({ rule: rule, value: row[fieldName], rowId: rowId, column: fieldName });
        }
      }
    }
  })

  return errors;
}

export const SheetPage = (props: SheetPageProps) => {
  const { hasPermission } = useContext(PermissionContext);
  const gridRef = useRef<DataGridHandle>(null);
  const [sheetData, setSheetData] = useState<{ rows: IModel[], errors: ValidatedCellError[], modifiedEntriesIds: string[], deletedEntriesIds: string[] }>({ rows: [], errors: [], modifiedEntriesIds: [], deletedEntriesIds: [], });
  const [selectedRowsIds, setSelectedRowsIds] = useState((): ReadonlySet<string> => new Set());
  const [selectedCell, setSelectedCell] = useState<{ rowIdx: number, columnKey: string }>({ rowIdx: -1, columnKey: "" });
  const [columns, setColumns] = useState<Column<IModel>[]>(props.sheetColumns);
  const [columnsOrder, setColumnsOrder] = useState((): readonly number[] =>
    columns.map((_, index) => index)
  );
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [toastInfo, setToastInfo] = useState<{ message: string, show: boolean, type: "success" | "error" | "warning" | "info" }>({ message: '', type: 'info', show: false });
  const [showColumns, setShowColumns] = useState<Column<IModel>[]>([]);

  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  function scrollToLastRow() {
    gridRef.current!.scrollToCell({ rowIdx: sheetData.rows.length - 1 });
  }

  const reorderedColumns = useMemo(() => {
    return columnsOrder.map((index) => columns[index]);
  }, [columnsOrder]);

  const columnsToDisplay = useMemo(() => {
    return showColumns?.filter(x => x.name !== "").map(x => x.name as string);
  }, [showColumns]);

  const columnsName = useMemo(() => {
    return columns?.filter(x => x.name !== "").map(x => x.name as string);
  }, [columns]);

  const sortedRows = useMemo((): readonly IModel[] => {
    if (sortColumns.length === 0) return [...sheetData.rows];

    return [...sheetData.rows].sort((a, b) => {
      for (const sort of sortColumns) {
        if (props.hasEmptyProperties(b) || props.hasEmptyProperties(a)) {
          return 1;
        }
        else {
          const comparator = (props.getComparator || getComparator)(sort.columnKey);
          const compResult = comparator(a, b);
          if (compResult !== 0) {
            return sort.direction === 'ASC' ? compResult : -compResult;
          }
        }
      }
      return 0;
    });
  }, [sheetData, sortColumns]);

  useEffect(() => {
    var entries = [...props.initialEntries];
    var errors: ValidatedCellError[] = [];

    entries.forEach(entry => {
      const rowErrors = GetRowErrors(entry.id, entry, props.validationRules);
      errors = [...errors, ...rowErrors];
    })


    if (props.noEmptyRow !== true) {
      var emptyRow = structuredClone(props.defaultObject);

      emptyRow.id = crypto.randomUUID();
      entries.push(emptyRow);
    }


    setSheetData({ rows: entries as IModel[], errors: errors, modifiedEntriesIds: [], deletedEntriesIds: [] });
  }, [props.initialEntries]);

  useEffect(() => {
    if (columnsOrder.length === 0) {
      setColumnsOrder(columns.map((_, index) => index));
    }
    else {
      if (columns.length !== 0) {
        var newMaxIdx = columns.length;
        var maxIdx = columnsOrder.length;
        setColumnsOrder(oldColumnsOrder => {
          var newColumnsOrder = [...oldColumnsOrder];
          if (columns.length < columnsOrder.length) {
            for (let idx = newMaxIdx; idx < maxIdx; idx++) {
              var columnIdx = newColumnsOrder.findIndex(e => e == idx);
              newColumnsOrder.splice(columnIdx, 1);
            }
          }
          else {
            for (let idx = maxIdx; idx < newMaxIdx; idx++) {
              newColumnsOrder.push(idx);
            }
          }
          return newColumnsOrder;
        });
      }
    }

  }, [columns]);

  useEffect(() => {
    setShowColumns(props.sheetColumns);
  }, [columns])

  const onRowChanges = (changes: IModel[], indexes: RowsChangeData<IModel>) => {
    let newErrors = [...sheetData.errors];
    let newModifiedEntries = [...sheetData.modifiedEntriesIds];
    let newDeletedEntriesIds = [...sheetData.deletedEntriesIds];
    let toBeDeletedIds: string[] = [];

    if (props.noEmptyRow !== true && !props.hasEmptyProperties(changes[changes.length - 1])) { //daca a fost modificat ultimul rand, atunci se adauga unul gol dupa el
      var row = structuredClone(props.defaultObject);
      row.id = crypto.randomUUID();

      changes.push(row);
    }

    indexes.indexes.forEach(idx => {
      const rowId = changes[idx].id;
      if (props.hasEmptyProperties(changes[idx])) {
        toBeDeletedIds.push(rowId);
      }
      else {
        const filteredErrors = newErrors.filter(e => !(e.rowId === rowId)); //erorile celorlalte randuri in afara de cel curent
        const rowErrors = GetRowErrors(changes[idx].id, changes[idx], props.validationRules);
        newErrors = ([...filteredErrors, ...rowErrors]);

        if (!newModifiedEntries.some(e => e === rowId)) {
          newModifiedEntries = [...newModifiedEntries, rowId];
        }
      }

    });

    if (props.updateColumns) {
      setColumns(currentColumns => {
        var newColumns = props.updateColumns([...changes], [...currentColumns]);

        return newColumns;
      });
    }

    deleteEmptyRows(changes, newModifiedEntries, newErrors, toBeDeletedIds);

    newDeletedEntriesIds = newDeletedEntriesIds.concat([...toBeDeletedIds]);

    setSheetData({ rows: [...changes], errors: newErrors, modifiedEntriesIds: newModifiedEntries, deletedEntriesIds: newDeletedEntriesIds });
  }

  const deleteEmptyRows = (rows: IModel[], modifiedEntries: string[], errors: ValidatedCellError[], toBeDeletedIds: string[]): void => {
    toBeDeletedIds.forEach(id => {
      var rowIdx = rows.findIndex(e => e.id === id);
      var modifiedIdx = modifiedEntries.findIndex(e => e === id);
      var errorIdx = errors.findIndex(e => e.rowId === id);

      if (rowIdx !== rows.length - 1) {
        rows.splice(rowIdx, 1);
        if (modifiedIdx >= 0)
          modifiedEntries.splice(modifiedIdx, 1);
        if (errorIdx >= 0)
          errors.splice(errorIdx, 1);
      }
    });
  }

  const handleSave = () => {
    if (sheetData.errors.length > 0) {
      setToastInfo({ message: 'Exista erori in datele introduse. Va rugam sa corectati erorile inainte de a salva.', show: true, type: 'error' });
      return;
    }
    let modifiedEntries: IModel[] = [];
    let deletedEntries: string[] = sheetData.deletedEntriesIds;
    sheetData.modifiedEntriesIds.forEach(id => {
      var entry = sheetData.rows.find(e => e.id === id);
      if (entry) {
        modifiedEntries.push(entry);
      }
    })

    if (modifiedEntries.length > 0 || deletedEntries.length > 0) {
      props.onSave(modifiedEntries, deletedEntries)
        .then(r => {
          props.refreshTable();
          setToastInfo({ message: 'Datele au fost salvate cu succes.', show: true, type: 'success' });
        })
        .catch(e => setToastInfo({ message: 'Ceva nu a mers bine. Reincearca mai tarziu.', show: true, type: 'error' }));
    }
  }

  function rowKeyGetter(row: IModel) {
    return row.id;
  }

  const onColumnsReorder = (sourceKey: string, targetKey: string) => {
    setColumnsOrder((columnsOrder) => {

      const sourceColumnOrderIndex = columnsOrder.findIndex(
        (index) => columns[index].key === sourceKey
      );
      const targetColumnOrderIndex = columnsOrder.findIndex(
        (index) => columns[index].key === targetKey
      );
      const sourceColumnOrder = columnsOrder[sourceColumnOrderIndex];

      var newColumnsOrder = columnsOrder.toSpliced(sourceColumnOrderIndex, 1);
      newColumnsOrder.splice(targetColumnOrderIndex, 0, sourceColumnOrder);
      return newColumnsOrder;
    });
  }

  const onSelectedCellChange = (args: CellSelectArgs<IModel>) => {
    setSelectedCell({ rowIdx: args.rowIdx, columnKey: args.column.key });
  }

  function handlePaste(e: React.ClipboardEvent<HTMLDivElement>) {
    const clipboardData = e.clipboardData.getData("text");
    var splitClipboardData = clipboardData.split("\r\n").filter(e => /\S/.test(e)).map(e => e.split("\t"));

    if (selectedCell.rowIdx == -1) {
      return;
    }

    const startColumnIndex = columnsOrder.findIndex(
      (index) => columns[index].key === selectedCell.columnKey
    );

    var result = props.addPastedRows([...sheetData.rows], selectedCell.rowIdx, startColumnIndex, columnsOrder, splitClipboardData);
    var newSheetRows = result.rows;

    if (props.noEmptyRow !== true && newSheetRows.length > sheetData.rows.length) { //if any rows were added, a new empty row is added at the end
      var row = structuredClone(props.defaultObject) as IModel;
      row.id = crypto.randomUUID();
      newSheetRows.push(row);
    }

    onRowChanges(newSheetRows, { indexes: result.indexes });
  }

  function handleKeyDown(e) {
    if (e.code !== "Delete") {
      return;
    }

    if (selectedRowsIds.size === 0 && selectedCell.rowIdx >= 0) {
      var result = props.deleteCell(sheetData.rows, selectedCell.rowIdx, selectedCell.columnKey);
      var newSheetRows = result.rows;
      var newDeletedEntriesIds = sheetData.deletedEntriesIds;
      var newErrors = sheetData.errors;
      var newModifiedEntriesIds = sheetData.modifiedEntriesIds;

      if (result.deleted === true) {
        if (props.updateColumns) {
          var newColumns = props.updateColumns([...newSheetRows], columns);
          setColumns([...newColumns]);
        }

        if ((selectedCell.rowIdx != newSheetRows.length - 1) && props.hasEmptyProperties(newSheetRows[selectedCell.rowIdx])) {
          deleteEmptyRows(newSheetRows, newModifiedEntriesIds, newErrors, [newSheetRows[selectedCell.rowIdx].id]);
          //incearca sa vezi daca se sterge pe cazul asta

          setSheetData({ rows: [...newSheetRows], errors: newErrors, modifiedEntriesIds: newModifiedEntriesIds, deletedEntriesIds: newDeletedEntriesIds });
        }
        else {
          onRowChanges(newSheetRows, { indexes: [selectedCell.rowIdx] });
        }
      }
    }
    else if (selectedRowsIds.size > 0) {
      var newSheetRows = sheetData.rows;
      var newDeletedEntriesIds = sheetData.deletedEntriesIds;
      var newErrors = sheetData.errors;
      var newModifiedEntries = sheetData.modifiedEntriesIds;

      deleteEmptyRows(newSheetRows, newModifiedEntries, newErrors, selectedRowsIds);

      if (props.updateColumns) {
        var newColumns = props.updateColumns([...newSheetRows], columns);
        setColumns([...newColumns]);
      }

      newDeletedEntriesIds = newDeletedEntriesIds.concat([...selectedRowsIds]);

      setSelectedRowsIds(new Set());
      setSheetData({ rows: [...newSheetRows], errors: sheetData.errors, modifiedEntriesIds: sheetData.modifiedEntriesIds, deletedEntriesIds: newDeletedEntriesIds });
    }
  }

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<IModel>): void {
    if (!window.isSecureContext) {
      return;
    }

    var selectedRows: IModel[] = [];
    if (selectedRowsIds.size > 0) {
      selectedRowsIds.forEach(rowId => {
        selectedRows.push(sheetData.rows.find(i => i.id == rowId) as IModel);
      });

      if (selectedRowsIds.size == sheetData.rows.length) {
        selectedRows.pop();
      }

      var copiedString = props.rowsToString(selectedRows, columnsOrder);

      navigator.clipboard.writeText(copiedString);
    }
    else {
      navigator.clipboard.writeText(sourceRow[sourceColumnKey as keyof IModel]!.toString());
    }
  }

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<IModel>): IModel {
    return { ...targetRow, [columnKey]: sourceRow[columnKey as keyof IModel] };
  }

  const onShowColumnHandle = (selectedColumns: string[]) => {
    const toDisplay = props.sheetColumns.filter(x => selectedColumns.includes(x.name as string) || x.name === "");

    setShowColumns(toDisplay);
  }

  return <div className="w-100 pt-20 flex flex-col min-h-screen">
    <div className="w-100 flex justify-between align-center my-5">
      <h1 className="text-5xl font-semibold upt-text-dark">{props.title}</h1>
      <MultiselectDropdown label={"Afișează doar coloanele"} selectedValues={columnsToDisplay} values={columnsName} onChange={(c) => onShowColumnHandle(c)}/>
    </div>
    {
      sheetData.errors.length > 0 ? <div className="bg-rose-100 border-t-4 rounded-b text-rose-900 px-4 py-3 shadow-md mb-4" role="alert">
        <div className="flex">
          <div className="py-1"><svg className="fill-current h-6 w-6 text-rose-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
          <div>
            <p className="font-bold">Au fost găsite erori în următoarele schimbări</p>
            {sheetData.errors.map(e => <p className="text-sm">Coloana {camelToFlat(e.rule.fieldName)}, rândul {sheetData.rows.findIndex(r => r.id === e.rowId) + 1}, cu valoarea "{e.value}" are eroarea: {e.rule.errorMessage}.</p>)}
          </div>
        </div>
      </div> : <></>
    }

    <div className="mx-auto overflow-auto flex grow max-h-full max-w-full" onPaste={handlePaste} onKeyDown={handleKeyDown}>
      {
        sheetData.rows.length > 0 ?
          <DataGrid
            ref={gridRef}
            columns={showColumns}
            sortColumns={sortColumns}
            rows={sortedRows}
            onRowsChange={(newRows, indexes) => onRowChanges(newRows, indexes)}
            rowKeyGetter={rowKeyGetter}
            className='rdg-light rounded-lg bg-gray-100'
            selectedRows={selectedRowsIds}
            onSelectedRowsChange={e => {
              setSelectedRowsIds(e)
              // props.onSelectedRowsChange(e);
            }}
            defaultColumnOptions={{
              sortable: true,
              resizable: true
            }}
            onSelectedCellChange={onSelectedCellChange}
            onSortColumnsChange={onSortColumnsChange}
            onColumnsReorder={onColumnsReorder}
            rowClass={(row, index) => index % 2 ? 'sheet-page-row-odd' : 'sheet-page-row-even'}
            // onFill={handleFill}
            onCopy={handleCopy}
            headerRowHeight={80}
            style={{ height: "70vh" }}
          />
          : <></>
      }
    </div>
    {hasPermission(props.requiredEditPermission) && <div className="flex w-1o0 justify-end py-10">
      <button className="align-center justify-center bg-teal-600 text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline mr-5" onClick={() => scrollToLastRow()}>
        Adaugă un rând nou
      </button> 
      <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
        Salvează
      </button>
    </div>
    }
    {toastInfo.show ? <ToastAlert message={toastInfo.message} type={toastInfo.type} onClose={() => setToastInfo({ ...toastInfo, show: false })} /> : <></>}


  </div>;
}