import { useEffect, useState } from "react";
import { SheetPage } from "./SheetPage";
import { ValidationRule } from "../models/validation-rule.model";
import { Facultate } from "../models/facultate.model";
import { Universitate } from "../models/universitate.model";
import { LocGeografic } from "../models/loc-geografic.model";
import { GetFacultatiWithFilters, GetLocuriGeografice, GetUniversitati, GetValidationRules, HandleChanges } from "../api/ApiService";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { PermissionType } from "../store/permission-context";


export const FacultatiPage = () => {
  const [facultati, setFacultati] = useState<Facultate[]>([]);
  const [universitati, setUniversitati] = useState<Universitate[]>([]);
  const [locuriGeografice, setLocuriGeografice] = useState<LocGeografic[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setFacultati(x.facultati);
      setUniversitati(x.universitati);
      setLocuriGeografice(x.locuriGeografice);
      setValidationRules(x.validationRules);
      setLoading(false);
    })

  }, [filters]);

  const GetRequiredData = async (): Promise<{ facultati: Facultate[], universitati: Universitate[], locuriGeografice: LocGeografic[], validationRules: ValidationRule[] }> => {
    let facultati = (await GetFacultatiWithFilters(filters)).data;

    let universitati = (await GetUniversitati()).data;

    let locuriGeografice = (await GetLocuriGeografice()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('Facultate')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { facultati, universitati, locuriGeografice, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<Facultate>[] => {
    return [
      SelectColumn,
      {
        key: 'cod',
        name: 'Cod',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'universitate',
        name: 'Universitate',
        renderEditCell: (p) => {
          return <RenderDropdown {...p} options={universitati.map((d) => ({ label: d.nume, value: d.id }))} items={universitati} handleChange={handleUniversitateDropdownChange} foreignKeyItemKey='univId' />
        },
        renderCell: (p) => (<>{p.row.universitate?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'universitate')?.filterValue || ''}
            applyFilter={val => applyFilter('universitate', val)}
          />)
      },
      {
        key: 'locGeografic',
        name: 'Loc geografic',
        renderEditCell: (p) => {
          return <RenderDropdown {...p} options={locuriGeografice.map((d) => ({ label: d.nume, value: d.id }))} items={locuriGeografice} handleChange={handleLocGeograficDropdownChange} foreignKeyItemKey='locGeoId' />
        },
        renderCell: (p) => (<>{p.row.locGeografic?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'locGeografic')?.filterValue || ''}
            applyFilter={val => applyFilter('locGeografic', val)}
          />)
      }
    ]
  };

  const handleLocGeograficDropdownChange = (row: Facultate, columnKey: string, selected: DropdownOption | null, items: LocGeografic[]) => {
    if (selected) {
      const found = items.find(i => i.id === selected.value);
      if (!found) {
        row['locGeoId'] = '';
        delete row['locGeografic'];
      }
      else {
        row['locGeoId'] = found.id;
        row['locGeografic'] = found;
      }
    }
    else {
      row['locGeoId'] = '';
      delete row['locGeografic'];
    }

    return row;
  }

  const handleUniversitateDropdownChange = (row: LocGeografic, columnKey: string, selected: DropdownOption | null, items: Universitate[]) => {
    if (selected) {
      const found = items.find(i => i.id === selected.value);
      if (!found) {
        row['univId'] = '';
        delete row['universitate'];
      }
      else {
        row['univId'] = found.id;
        row['universitate'] = found;
      }
    }
    else {
      row['univId'] = '';
      delete row['universitate'];
    }

    return row;
  }

  const rowsToString = (rows: Facultate[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {
          case 'universitate':
            var universitate = universitati.find(e => e.id == row.universitate?.id);
            copiedString += universitate?.nume;
            break;
          case 'locGeografic':
            var locGeografic = locuriGeografice.find(e => e.id == row.locGeografic?.id);
            copiedString += locGeografic?.nume;
            break;
          default:
            copiedString += row[columnKey as keyof Facultate];
            break;
        }
        copiedString += separator;
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): Facultate[] => {
    var rows: Facultate[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: Facultate = structuredClone(defaultObject) as Facultate;
      row.id = crypto.randomUUID();
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'universitate':
            var universitate = universitati.find(e => e.nume == textRow[idx]);
            row['univId'] = universitate?.id ?? "";
            row['universitate'] = universitate;
            break;
          case 'locGeografic':
            var locGeografic = locuriGeografice.find(e => e.nume == textRow[idx]);
            row['locGeoId'] = locGeografic?.id ?? "";
            row['locGeografic'] = locGeografic;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: Facultate[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: Facultate[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as Facultate;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as Facultate;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'universitate':
            row['univId'] = transformedClipboardRows[clipboardRowIdx]['universitate']?.id || '';
            break;
          case 'locGeografic':
            row['locGeoId'] = transformedClipboardRows[clipboardRowIdx]['locGeografic']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  const deleteCell = (currentRows: Facultate[], rowIdx: number, columnKey: string): { rows: Facultate[], deleted: boolean } => {
    var deleted = true;

    switch (columnKey) {
      case 'universitate':
        currentRows[rowIdx]['univId'] = '';
        delete currentRows[rowIdx]['universitate'];
        break;
      case 'locGeografic':
        currentRows[rowIdx]['locGeoId'] = '';
        delete currentRows[rowIdx]['locGeografic'];
        break;
      default:
        deleted = false;
        break;
    }

    return { rows: currentRows, deleted: deleted };
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: Facultate): boolean => {
    if (row.cod !== '' || !!row.cod) return false;
    if (row.nume !== '' || !!row.nume) return false;
    if (row.univId !== '' || !!row.univId) return false;
    if (row.locGeoId !== '' || !!row.locGeoId) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: Facultate = { id: '', cod: '', nume: '', univId: '', locGeoId: '' };

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SheetPage
    requiredEditPermission={PermissionType.FacultateWrite}
    sheetColumns={columns}
    title={'Facultati'}
    hasEmptyProperties={e => hasEmptyProperties(e as Facultate)}
    initialEntries={facultati}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'facultate')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as Facultate[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}
