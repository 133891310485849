import { Domeniu } from "./domeniu.model";
import { IPlanInvatamantEntryModel } from "./generic-plan-invatamant-entry.model";
import { IModel } from "./generic.model";
import { IndicatorPlan } from "./indicator-plan.model";
import { Specializare } from "./specializare.model";

export interface PlanEntry {
    intrare: IPlanInvatamantEntryModel;
    tip: PlanEntryType;
    ordine: number;
};

export enum PlanEntryType {
    Empty = "Empty",
    Materie = "MaterieViewModel",
    Grupare = "GrupareViewModel",
    GrupareSubspecializazre = "GrupareSubspecializareViewModel"
}

export enum MaterieCategoryType {
    EducatieFizica = "Educatie Fizica",
    Practica = "Practica",
    ElaborareProiectDiploma = "Elaborare Proiect Diploma",
    ExamenDiploma = "Examen Diploma",
}
