import { createContext, useEffect, useState } from "react";
import { GetDomenii, GetSpecializari } from "../api/ApiService";
import { Specializare } from "../models/specializare.model";
import { Domeniu } from "../models/domeniu.model";

const GetRequiredData = async (): Promise<{ specializari: Specializare[], domenii: Domeniu[] }> => {

  let specializari = (await GetSpecializari()).data;

  let domenii = (await GetDomenii()).data;

  return { specializari, domenii }
}

interface PlanContextType {
  specializari: Specializare[],
  domenii: Domeniu[]
}

export const PlanContext = createContext<PlanContextType>({
  specializari: [],
  domenii: []
});

export default function PlanContextProvider({ children }) {
  const [specializari, setSpecializari] = useState<Specializare[]>([]);
  const [domenii, setDomenii] = useState<Domeniu[]>([]);

  useEffect(() => {
    GetRequiredData().then(x => {
      setSpecializari(x.specializari);
      setDomenii(x.domenii);
    })

  }, []);

  const ctxValue = {
    specializari: specializari,
    domenii: domenii
  }

  return <PlanContext.Provider value={ctxValue}>
    {children}
  </PlanContext.Provider>
}