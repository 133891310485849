import { useContext } from "react";
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { GrupareSubspecializare } from "../../models/grupare-subspecializare.model";
import { Grupare, GrupareCategoryEnum } from "../../models/grupare.model";
import { CategorieFormativaEnum, DisplayCategorieFormativaEnum, DisplayFormaEvaluareEnum, Materie } from "../../models/materie.model";
import { PlanEntryType } from "../../models/plan-entry.model";
import ComboBox from "../Combobox";
import { DetailBox } from "./DetailBox";
import { PlanTableContext } from "../../store/plan-table-context";
import { PlanTableType } from "../../models/plan-invatamant-table-data.model";

interface PlanEntryProps {
  entry: IPlanInvatamantEntryModel,
  type: string,
  an: number,
  semestru: number,
  index: number,
  comboBoxOptions: { label: string, value: string }[],
  handleChange: (selected, an, semestru, idx) => void
}

export const PlanEntry = (props: PlanEntryProps) => {
  var { tableType } = useContext(PlanTableContext);

  let entry: Materie = {} as Materie;
  let bgColor = "bg-green-50";

  if (tableType == PlanTableType.Facultative) {
    entry = structuredClone(props.entry) as Materie;
    bgColor = "bg-gray-300";
  }
  else {
    if (props.type === PlanEntryType.Grupare) {
      let grupare = props.entry as Grupare;
      if (grupare.materie && grupare.materie.length > 0) {
        entry = structuredClone(grupare.materie[0]);
        entry.id = grupare.id;
        console.log(grupare.category);
        if (grupare.category === GrupareCategoryEnum.Facultative) {
          entry = {} as Materie;
          entry.nume = "Disciplina facultativa";
          entry.categorieFormativa = CategorieFormativaEnum.f
          bgColor = "bg-gray-300";
        }
        else {
          entry.nume = "Optionala din setul " + grupare.nume;
          bgColor = "bg-purple-100";
        }
      }
    }

    else if (props.type === PlanEntryType.Materie) {
      entry = structuredClone(props.entry) as Materie;
    }
    else if (props.type === PlanEntryType.GrupareSubspecializazre) {
      let grupareSubspecializare = props.entry as GrupareSubspecializare;
      if (grupareSubspecializare.grupare && grupareSubspecializare.grupare.length > 0) {
        let grupare = grupareSubspecializare.grupare[0] as Grupare;
        if (grupare.materie && grupare.materie.length > 0) {
          entry = structuredClone(grupare.materie[0]);
          entry.id = grupare.id;
          entry.nume = "Optionala din setul " + grupareSubspecializare.nume;
          bgColor = "bg-amber-100";
        }
      }
    }
  }
  // console.log(props.type);

  return (
    <section className="w-full">
      <div className={"flex flex-col justify-center items-center " + bgColor}>
        <div className="w-full h-full">
          {tableType != PlanTableType.IntrariPlan ?
            <div className="flex justify-center min-h-7 max-h-7 p-2 text-ellipsis overflow-hidden">
              {entry.nume}
            </div>
            :
            <div className="">
              <ComboBox options={[{ label: "", value: "" }, ...props.comboBoxOptions]} value={{ label: entry.nume || "", value: entry.id || "" }} onChange={v => props.handleChange(v, props.an, props.semestru, props.index)} />
            </div>
          }
        </div>
        <div className="w-full flex">
          <DetailBox value={entry.cod} tooltip="Cod" />
          <DetailBox value={entry.nrCredite} tooltip="Numar credite" />
          <DetailBox value={DisplayFormaEvaluareEnum[entry.formaEvaluare]} tooltip="Forma evaluare" />
          <DetailBox value={entry.oreCurs} tooltip="Ore curs" />
          <DetailBox value={entry.oreSeminar} tooltip="Ore seminar" />
          <DetailBox value={entry.oreLaborator} tooltip="Ore laborator" />
          <DetailBox value={entry.oreProiect} tooltip="Ore proiect" />
          <DetailBox value={entry.orePractica} tooltip="Ore practica" />
          <DetailBox value={DisplayCategorieFormativaEnum[entry.categorieFormativa]} tooltip="Categorie formativa" />
          <DetailBox value={entry.oreIndividuale} tooltip="Ore individuale" />
        </div>
      </div>
    </section>
  );
}