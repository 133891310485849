import { useEffect, useMemo, useState } from "react";
import { SheetPage } from "./SheetPage";
import { DisplayGrupareCategoryEnum, Grupare, GrupareCategoryEnum } from "../models/grupare.model";
import { GetGrupariWithFilters, GetMaterii, GetSpecializari, GetValidationRules, HandleChanges } from "../api/ApiService";
import { ValidationRule } from "../models/validation-rule.model";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { Specializare } from "../models/specializare.model";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { Materie } from "../models/materie.model";
import { PermissionType } from "../store/permission-context";


export const GrupariPage = () => {
  const [grupari, setGrupari] = useState<Grupare[]>([]);
  const [specializari, setSpecializari] = useState<Specializare[]>([]);
  const [materii, setMaterii] = useState<Materie[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setValidationRules(x.validationRules);
      setGrupari(x.grupari);
      setSpecializari(x.specializari);
      setMaterii(x.materii);
      setLoading(false);
    })

  }, [filters]);

  const GetRequiredData = async (): Promise<{ grupari: Grupare[], specializari: Specializare[], materii: Materie[], validationRules: ValidationRule[] }> => {
    let grupari = (await GetGrupariWithFilters(filters)).data;

    let specializari = (await GetSpecializari()).data;

    let materii = (await GetMaterii()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('Grupare')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { grupari, specializari, materii, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName), { filterName, filterValue }]);
  }

  const columns = useMemo((): Column<Grupare>[] => {
    var columns = [
      SelectColumn,
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        frozen: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'specializare',
        name: 'Specializare',
        renderEditCell: (p) => <RenderDropdown {...p} options={specializari.map((d) => ({ label: d.nume, value: d.id }))} items={specializari} handleChange={handleSpecializareDropdownChange} foreignKeyItemKey='specId' />,
        renderCell: (p) => (<>{p.row.specializare?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'specializare')?.filterValue || ''}
            applyFilter={val => applyFilter('specializare', val)}
          />)
      },
      {
        key: 'an',
        name: 'An',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'semestru',
        name: 'Semestru',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'category',
        name: 'Categorie',
        renderEditCell: (p) => <RenderDropdown {...p} options={Object.keys(DisplayGrupareCategoryEnum).map((key) => ({ label: DisplayGrupareCategoryEnum[key], value: key }))} items={[]} handleChange={handleNonNullableDropdownChange} />,
        renderCell: (p) => (<>{DisplayGrupareCategoryEnum[p.row.category]}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'category')?.filterValue || ''}
            applyFilter={val => applyFilter('category', val)}
          />)
      },
    ];

    var maxMateriiLength = Math.max(...grupari.map(e => e.materie?.length || 0));

    for (let idx = 1; idx <= maxMateriiLength + 1; idx++) {
      columns.push({
        key: `materie${idx}`,
        name: `Materie ${idx}`,
        renderEditCell: (p) => <RenderDropdown {...p} options={materii.filter(m => m.specId == p.row.specId && m.an == p.row.an && m.semestru == p.row.semestru).map((d) => ({ label: d.nume, value: d.id }))} items={materii} handleChange={handleMateriiDropdownChange} foreignKeyItemKey='materie' />,
        renderCell: (p) => (<>{p.row.materie[idx - 1]?.nume}</>)
      });
    }

    return columns;
  }, [materii]);

  const handleMateriiDropdownChange = (row: Grupare, columnKey: string, selected: DropdownOption | null, items: Materie[]) => {
    var idx = +columnKey.substring("materie".length);
    if (selected) {
      const foundMaterie = items.find(i => i.id === selected.value);
      if (!foundMaterie) {
        row.materie?.splice(idx - 1, 1);
      }
      else {
        row.materie = row.materie || [];
        row.materie[idx - 1] = foundMaterie;
      }
    }
    else {
      row[columnKey] = null;
    }

    return row;
  }

  const handleSpecializareDropdownChange = (row: Grupare, columnKey: string, selected: DropdownOption | null, items: Specializare[]) => {
    if (selected) {
      const foundSpecializare = items.find(i => i.id === selected.value);
      if (!foundSpecializare) {
        row['specId'] = '';
        delete row['specializare'];
      }
      else {
        row['specId'] = foundSpecializare.id;
        row['specializare'] = foundSpecializare;
      }
    }
    else {
      row['specId'] = '';
      delete row['specializare'];
    }

    return row;
  }

  const handleNonNullableDropdownChange = (row: Materie, columnKey: string, selected: DropdownOption | null, items: []) => {
    if (selected) {
      row[columnKey] = selected.value;
    }
    return row;
  }

  const rowsToString = (rows: Grupare[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = columns.map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length - 1; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];

        if (columnKey.includes("materie") && row['materie']) {
          var materieIdx = +columnKey.substring("materie".length) - 1;
          var materie = row['materie'][materieIdx];

          copiedString += materie?.nume;
        }
        else {
          switch (columnKey) {
            case 'specializare':
              var specializare = row['specializare'];
              // var specializare = specializari.find(e => e.id == row.specializare?.id);
              copiedString += specializare?.nume;
              break;
            default:
              copiedString += row[columnKey as keyof Grupare];
              break;
          }
        }
        copiedString += '\t';
      }
      copiedString += '\r\n'
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): Grupare[] => {
    var rows: Grupare[] = [];
    const columnsKeys = columns.map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: Grupare = structuredClone(defaultObject) as Grupare;
      row.id = crypto.randomUUID();
      var materieColumnsStartIdx = columns.findIndex(e => e.key == 'materie1') - 1;

      for (let idx = 0; idx < materieColumnsStartIdx; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'specializare':
            var specializare = specializari.find(e => e.nume == textRow[idx]);
            row['specId'] = specializare?.id ?? "";
            row['specializare'] = specializare;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }

      for (let idx = materieColumnsStartIdx; idx < textRow.length; idx++) {
        var materie = materii.find(e => e.nume == textRow[idx]);
        if (materie) {
          row['materie']?.push(materie);
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: Grupare[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: Grupare[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as Grupare;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as Grupare;
      }

      var materieColumnsStartIdx = columns.findIndex(e => e.key == 'materie1') - 1;
      for (let idx = columnIdx - 1; idx < materieColumnsStartIdx; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'specializare':
            row['specId'] = transformedClipboardRows[clipboardRowIdx]['specializare']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      row['materie'] = row['materie']?.concat(transformedClipboardRows[clipboardRowIdx].materie ?? []);

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  const deleteCell = (currentRows: Grupare[], rowIdx: number, columnKey: string): { rows: Grupare[], deleted: boolean } => {
    var deleted = true;

    if (columnKey.includes("materie")) {
      currentRows[rowIdx]['materie']?.splice(+columnKey.substring("materie".length) - 1, 1);
    }
    else {
      switch (columnKey) {
        case 'specializare':
          currentRows[rowIdx]['specId'] = '';
          delete currentRows[rowIdx]['specializare'];
          break;
        default:
          deleted = false;
          break;
      }
    }

    return { rows: currentRows, deleted: deleted };
  }

  const updateColumns = (currentRows: Grupare[], columns: Column<Grupare>[]): Column<Grupare>[] => {
    const maxLength = Math.max(...currentRows.map(row => row.materie?.length || 0));
    const maxColumnNumber = Math.max(...columns.filter(e => e.key.includes("materie")).map(column => +column.key.substring("materie".length)));

    if (maxLength < maxColumnNumber - 1) {
      columns.splice(columns.length - maxColumnNumber + maxLength + 1, maxColumnNumber - maxLength - 1);
    }
    else if (maxLength > maxColumnNumber - 1) {
      for (let idx = maxColumnNumber + 1; idx <= maxLength + 1; idx++) {
        columns.push({
          key: `materie${idx}`,
          name: `Materie ${idx}`,
          renderEditCell: (p) => <RenderDropdown {...p} options={materii.filter(m => m.specId == p.row.specId && m.an == p.row.an && m.semestru == p.row.semestru).map((d) => ({ label: d.nume, value: d.id }))} items={materii} handleChange={handleMateriiDropdownChange} foreignKeyItemKey='materie' />,
          renderCell: (p) => (<>{p.row.materie[idx - 1]?.nume}</>)
        });
      }
    }

    return columns;
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: Grupare): boolean => {
    if (row.nume !== '' || !!row.nume) return false;
    if (row.specId !== '' || !!row.specId) return false;
    if (row.materie?.length != 0) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  let defaultObject: Grupare = { id: '', nume: '', an: '', semestru: '', specId: '', materie: [], createdTime: new Date() };

  if (loading) {
    return <div>Loading...</div>;
  }

  // return <></>
  return <SheetPage
    requiredEditPermission={PermissionType.GrupareWrite}
    sheetColumns={columns}
    title={'Grupari'}
    hasEmptyProperties={e => hasEmptyProperties(e as Grupare)}
    initialEntries={grupari}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'grupare')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as Grupare[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    updateColumns={(currentRows, columns) => updateColumns(currentRows, columns)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}
