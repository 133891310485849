import { Button } from "@mui/material";
import { YearTable } from "./YearTable";
import AddIcon from '@mui/icons-material/Add';
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { useContext, useState } from "react";
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";
import { YearTableData } from "../../models/year-table-data.model";
import { PlanTableContext } from "../../store/plan-table-context";
import { PlanTableType } from "../../models/plan-invatamant-table-data.model";

function pairContainsData(planDataPair: { year1: YearTableData; year2?: YearTableData; }) {
  var containsData = false;

  for (var idx = 0; idx < 2; idx++) {

    if (planDataPair.year1.semesters[idx].intrari.length > 0) {
      containsData = true;
    }
    if (planDataPair.year2 && planDataPair.year2.semesters[idx].intrari.length > 0) {
      containsData = true;
    }

  }

  return containsData;
}

interface YearPairTableProps {
  year1: YearTableData,
  year2?: YearTableData,
  index: number,
  materii,
  grupari,
  currentYear: number,
  handleAddButton: (index: number) => void
  handleChange: (selected, an, semestru, idx) => void
}

export const YearPairTable = (props: YearPairTableProps) => {
  var { tableType } = useContext(PlanTableContext);

  return (
    <>
      {
        (tableType == PlanTableType.IntrariPlan || pairContainsData({ year1: props.year1, year2: props.year2 })) ?
          <>
            <div className="flex w-full overflow-x-auto rounded-lg">

              <YearTable key={2 * props.index} yearData={props.year1} index={2 * props.index} materii={props.materii} grupari={props.grupari} currentYear={props.currentYear} handleChange={props.handleChange} />
              {
                props.year2 ?
                  <YearTable key={2 * props.index + 1} yearData={props.year2} index={2 * props.index + 1} materii={props.materii} grupari={props.grupari} currentYear={props.currentYear + 1} handleChange={props.handleChange} />
                  :
                  <></>
              }

            </div>
            {
              tableType == PlanTableType.IntrariPlan ?
                <div className="w-full flex shadow mb-20 bg-indigo-100">
                  <Button startIcon={<AddIcon />} onClick={e => props.handleAddButton(props.index)} style={{ width: "100%" }}>Adauga rand pentru o materie sau o grupare</Button>
                </div>
                :
                <div className="mb-20"></div>
            }
          </>
          :
          <></>
      }
    </>
  );
}