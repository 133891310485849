import { CellStyle } from "@silevis/reactgrid";
import { DropdownEntry } from "../pages/SheetPage";
import { IModel } from "../models/generic.model";
import { DisplayUnitateMasuraEnumEnum, IndicatorPlan, TipComparatie, TipValoare } from "../models/indicator-plan.model";
import { PlanSummaryEntryModel } from "../models/plan-summary-entry.model";

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export const headerCellStyle: CellStyle = {
  background: "rgb(30 58 138)",
  color: "white"
}

export const headerCellClassNames = "font-bold"

export const camelToFlat = (camel: string) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")

  let flat = ""

  camelCase.forEach(word => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " "
  })
  return flat
}

export const isDropdownOpen = (dropdownEntries: DropdownEntry[], fieldName: string, rowId: number) => {
  let dropdown = dropdownEntries.find(d => d.fieldName === fieldName && d.rowId === rowId);

  if (dropdown === undefined || dropdown === null) return false;

  return dropdown.isOpen;
}

export type Comparator = (a: IModel, b: IModel) => number;

export const getCriteriu = (indicatorPlan: PlanSummaryEntryModel | undefined) => {
  var criteriu = '';

  if (indicatorPlan === undefined)
    return '';

  switch (indicatorPlan.tipComparatie) {
    case TipComparatie.ValoareExacta:
      criteriu += `${indicatorPlan.valoare}`
      break;
    case TipComparatie.ValoareMinima:
      criteriu += `min ${indicatorPlan.valoare}`
      break;
    case TipComparatie.ValoareMaxima:
      criteriu += `max ${indicatorPlan.valoare}`
      break;
    case TipComparatie.Interval:
      criteriu += `${indicatorPlan.startInterval}-${indicatorPlan.finalInterval}`
      break;
    default:
      break;
  }

  criteriu += DisplayUnitateMasuraEnumEnum[indicatorPlan.unitateMasura] || '';

  return criteriu;
}