import { SheetPage } from "./SheetPage";
import { ValidationRule } from "../models/validation-rule.model";
import { GetUniversitatiWithFilters, GetValidationRules, HandleChanges } from "../api/ApiService";
import { useEffect, useState } from "react";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { Universitate } from "../models/universitate.model";
import { PermissionType } from "../store/permission-context";

export const UniversitatiPage = () => {
  const [universitati, setUniversitati] = useState<Universitate[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setUniversitati(x.universitati);
      setValidationRules(x.validationRules);
      setLoading(false);
    })
  }, [filters]);

  const GetRequiredData = async (): Promise<{ universitati: Universitate[], validationRules: ValidationRule[] }> => {
    let universitati = (await GetUniversitatiWithFilters(filters)).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('Universitate')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { universitati, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<Universitate>[] => {
    return [
      SelectColumn,
      {
        key: 'cod',
        name: 'Cod',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      }
    ]
  };

  const rowsToString = (rows: Universitate[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {

          default:
            copiedString += row[columnKey as keyof Universitate];
            break;
        }
        copiedString += separator
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): Universitate[] => {
    var rows: Universitate[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: Universitate = structuredClone(defaultObject) as Universitate;
      row.id = crypto.randomUUID();
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {

          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: Universitate[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: Universitate[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as Universitate;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as Universitate;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          //No foreign key on UniversitatiPage
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: Universitate): boolean => {
    if (row.nume !== '' || !!row.nume) return false;
    if (row.cod !== '' || !!row.cod) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: Universitate = { id: '', nume: '', cod: '' };

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SheetPage
    requiredEditPermission={PermissionType.UniversitateWrite}
    sheetColumns={columns}
    title={'Universități'}
    hasEmptyProperties={e => hasEmptyProperties(e as Universitate)}
    initialEntries={universitati}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'universitate')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as Universitate[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}