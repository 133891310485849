
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";
import { DetailBox } from "./DetailBox";
import { ErrorTooltip } from "./ErrorTooltip";

interface SemesterSummaryProps {
    summary: PlanSummaryEntryModel[]
}

export const SemesterSummary = (props: SemesterSummaryProps) => {
    const { summary } = props;
    // console.log(summary);
    const oreSemestru = summary.find(e => e.slug === "ore-semestru");

    const crediteSemestru = summary.find(e => e.slug === "credite-semestru");

    const crediteExamenDiploma = summary.find(e => e.slug === "credite-examen-diploma-total");

    const oreSaptamana = summary.find(e => e.slug === "ore-saptamana");

    const oreIndividualeTotal = summary.find(e => e.slug === "ore-individuale-total");

    const evaluariExamen = summary.find(e => e.slug === "evaluari-examen");

    const evaluariDistribuita = summary.find(e => e.slug === "evaluari-distribuita");

    const evaluariColocviu = summary.find(e => e.slug === "evaluari-colocviu");

    const errorClass = "bg-red-200";

    return (
        <div className="flex w-full flex-col bg-cyan-50 border-t-4 border-indigo-500">

            {/*row 1*/}
            <div className="w-full flex">
                <section className="h-auto flex border-x border-gray-400 bg-pink-100">
                    <section className=" h-auto flex flex-col justify-center">
                        <p className="px-1">total/</p>
                        <p className="px-1">sem.</p>
                    </section>
                </section>
                <section className="w-full flex">
                    <section className="w-full flex flex-col">
                        <div className="w-full flex justify-between px-3 py-1 border-gray-400 border-e">
                            <p>ore:</p>
                            <p className="font-semibold">{oreSemestru?.planValue}</p>
                        </div>
                        <div className={"flex px-3 py-1 border-gray-400 border-e border-y " + (crediteSemestru?.error ? errorClass : "")}>
                            {
                                crediteSemestru?.error ?
                                    <ErrorTooltip tooltip={crediteSemestru?.error} />
                                    :
                                    <></>
                            }
                            <div className="w-full flex justify-between">
                                <p>credite:</p>
                                <p className="font-semibold">
                                    {crediteSemestru?.planValue}
                                    {
                                        crediteExamenDiploma?.planValue != 0 ?
                                            <>+{crediteExamenDiploma?.planValue}</>
                                            :
                                            <></>
                                    }
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="w-full flex flex-col">
                        <div className="w-full flex justify-between px-3 py-1 border-gray-400 border-e">
                            <p>VPI:</p>
                            <p className="font-semibold">{oreIndividualeTotal?.planValue}</p>
                        </div>
                        <div className="w-full flex justify-between px-3 py-1 border-gray-400 border-e border-y">
                            <p>evaluări:</p>
                            <p className="font-semibold">{evaluariExamen?.planValue || 0}E,{evaluariDistribuita?.planValue || 0}D,{evaluariColocviu?.planValue || 0}C</p>
                        </div>

                    </section>
                </section>
            </div>


            {/*row 2*/}
            <div className="w-full flex">
                <section className="h-auto flex border-gray-400 border bg-pink-100">
                    <section className=" h-auto flex flex-col justify-center">
                        <p className="px-1">total/</p>
                        <p className="px-1">sapt.</p>
                    </section>
                </section>
                <section className="w-full flex flex-col">
                    <div className={"flex px-3 py-1 border-gray-400 border-e border-y " + (oreSaptamana?.error ? errorClass : "")}>
                        {
                            oreSaptamana?.error ?
                                <ErrorTooltip tooltip={oreSaptamana?.error} />
                                :
                                <></>
                        }
                        <div className="flex w-full justify-between">

                            <p>ore:</p>
                            <p className="font-semibold">{oreSaptamana?.planValue}</p>
                        </div>
                    </div>
                    <div className="w-full flex justify-between px-3 border-gray-400 border-e border-y">
                        <p>din care:</p>
                        <p className="font-semibold"></p>
                    </div>
                </section>
                <section className="w-full flex">
                    <div className="w-full flex flex-col justify-around">
                        <p className="min-h-8"></p>
                        <div className="w-full flex">
                            <DetailBox value={summary.find(e => e.slug === "ore-curs-saptamana")?.planValue || 0} />
                            <DetailBox value={summary.find(e => e.slug === "ore-seminar-saptamana")?.planValue || 0} />
                            <DetailBox value={summary.find(e => e.slug === "ore-laborator-saptamana")?.planValue || 0} />
                            <DetailBox value={summary.find(e => e.slug === "ore-proiect-saptamana")?.planValue || 0} />
                            <DetailBox value="(c,s,l,p)" />
                        </div>
                    </div>
                </section>
            </div>

        </div>

    );
}