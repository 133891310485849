
import "@silevis/reactgrid/styles.css";
import { Navbar } from "./components/Navbar";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { FacultatiPage } from "./pages/FacultatiPage";
import { MateriiPage } from "./pages/MateriiPage";
import { LocuriGeograficePage } from "./pages/LocuriGeograficePage";
import { CicluriStudiuPage } from "./pages/CicluriStudiuPage";
import { GrupariPage } from "./pages/GrupariPage";
import { PlanuriInvatamantPage } from "./pages/PlanuriInvatamantPage";
import { PlanInvatamantPage } from "./pages/PlanInvatamantPage";
import IndicatoriPage from "./pages/IndicatoriPage";
import { SpecializariPage } from "./pages/SpecializariPage";
import { DomeniiPage } from "./pages/DomeniiPage";
import { RamuriStiintaPage } from "./pages/RamuriStiintaPage";
import { DomeniiFundamentalePage } from "./pages/DomeniiFundamentalePage";
import { UniversitatiPage } from "./pages/UniversitatiPage";
import { useAuth } from "react-oidc-context";
import PermissionsContextProvider from "./store/permission-context";
import { GrupariSubspecializariPage } from "./pages/GrupariSubspecializariPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <div className="min-h-screen bg-gray-100 flex flex-col">
      <Navbar />
      <div className="px-20 w-100">
        <Outlet />
      </div>
    </div>,
    children: [
      {
        path: "/universitati",
        element: <UniversitatiPage />,
      },
      {
        path: "/facultati",
        element: <FacultatiPage />,
      },
      {
        path: "/domenii-fundamentale",
        element: <DomeniiFundamentalePage />,
      },
      {
        path: "/ramuri-de-stiinta",
        element: <RamuriStiintaPage />,
      },
      {
        path: "/domenii",
        element: <DomeniiPage />,
      },
      {
        path: "/specializari",
        element: <SpecializariPage />,
      },
      {
        path: "/materii",
        element: <MateriiPage />,
      },
      {
        path: "/grupari",
        element: <GrupariPage />,
      },
      {
        path: "/grupari-subspecializari",
        element: <GrupariSubspecializariPage />,
      },
      {
        path: "/planuri-invatamant",
        element: <PlanuriInvatamantPage />,
      },
      {
        path: "/planuri-invatamant/:id",
        element: <PlanInvatamantPage />,
      },
      {
        path: "/planuri-invatamant/:id/indicatori",
        element: <IndicatoriPage />,
      },
      {
        path: "/locuri-geografice",
        element: <LocuriGeograficePage />,
      },
      {
        path: "/cicluri-de-studiu",
        element: <CicluriStudiuPage />,
      },
      {
        path: "*",
        element: <MateriiPage />,
      },
    ]
  },
]);


function App() {
  const auth = useAuth();


  // var targetOP = "https://idp.upt.ro"; //url of the OP
  // window.addEventListener("message", receiveMessage, false);

  // var scheduledCheck;

  // window.onload = function () {
  //   // Check shortly after onload
  //   setTimeout(checkStatus, 2000);
  //   // Do not forget to deregister scheduled callback using clearInterval(checkStatus)
  //   scheduledCheck = setInterval(checkStatus, 1000 * 5); //Every 20 seconds
  // };

  // function checkStatus() {
  //   var client = "oidc"; //this should be the clientId that is configured on the OP
  //   var sessionState = auth.user?.session_state; 
  //   var text = client + " " + sessionState;
  //   var iframe = window.parent.document.getElementById("opif"); //this should be the id of the OP iframe you set when including them on the main page
  //   iframe.contentWindow.postMessage(text, targetOP);
  // }

  // function receiveMessage(event) {
  //   if (event.origin !== targetOP) {
  //     // Origin did not come from the OP; this message must be rejected
  //     return;
  //   }
  //   if (event.data === "unchanged") {
  //     // User is still logged into the OP, don't need to do anything
  //   } else {
  //     // Detected a change at OP
  //     // Perform authentication with `prompt=none` to check authentication status
  //   }
  // }



  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <PermissionsContextProvider>
        <RouterProvider router={router} />
      </PermissionsContextProvider>
    );
  }

  auth.signinRedirect();
  // return 

}


export default App;
