import { Domeniu } from "./domeniu.model";
import { IModel } from "./generic.model";
import { Materie } from "./materie.model";
import { Specializare } from "./specializare.model";


export interface Grupare extends IModel {
    specId: string,
    nume: string,
    an: number,
    semestru: number,
    category: GrupareCategoryEnum,
    specializare?: Specializare,
    materie?: Materie[],
}

export enum GrupareCategoryEnum {
    Optionale = "Optionale",
    Facultative = "Facultative",
}

export enum DisplayGrupareCategoryEnum {
    Optionale = "Optionale",
    Facultative = "Facultative",
}