import { IModel } from "./generic.model";
import { Indicator } from "./indicator.model";

export interface IndicatorPlan extends IModel {
    indiceId: string;
    planInvatamantId: string;
    indice: Indicator;
    nume: string;
    tip?: string;
    tipValoare?: string;
    tipComparatie?: string;
    valoare: number;
    startInterval: number;
    finalInterval: number;
    unitateMasura: string;
}

export enum TipComparatie {
    ValoareExacta = "ValoareExacta",
    ValoareMinima = "ValoareMinima",
    ValoareMaxima = "ValoareMaxima",
    Interval = "Interval"
}

export enum TipValoare {
    Valoare = "Valoare",
    Procent = "Procent"
}

export enum TipSummary {
    An = "An",
    Semestru = "Semestru"
}

export enum UnitateMasuraEnum {
    Ore = "Ore",
    Credite = "Credite",
    Procente = "Procente",
    Saptamani = "Saptamani",
    Ani = "Ani",
    Studenti = "Studenti",
}


export enum DisplayUnitateMasuraEnumEnum {
    Ore = " ore",
    Credite = " credite",
    Procente = "%",
    Saptamani = " saptamani",
    Ani = " ani",
    Studenti = " studenti",
}
