import { createContext } from "react";

import { PlanTableType } from "../models/plan-invatamant-table-data.model";

interface PlanTableContextType {
  tableType: PlanTableType
}

export const PlanTableContext = createContext<PlanTableContextType>({
  tableType: PlanTableType.IntrariPlan
});