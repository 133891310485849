import { PlanSummaryEntryModel as PlanSummaryEntryModel } from "./plan-summary-entry.model";
import { YearTableData } from "./year-table-data.model";

export interface PlanInvatamantTableData {
    years: YearTableData[],
    summary: PlanSummaryEntryModel[]
}

export enum PlanTableType {
    IntrariPlan,
    Optionale,
    Facultative
}