import { useEffect, useMemo, useState } from "react";
import { SheetPage } from "./SheetPage";
import { GetGrupari, GetGrupariSubspecializariWithFilters, GetGrupariWithFilters, GetSpecializari, GetValidationRules, HandleChanges } from "../api/ApiService";
import { ValidationRule } from "../models/validation-rule.model";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { Specializare } from "../models/specializare.model";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { Grupare } from "../models/grupare.model";
import { PermissionType } from "../store/permission-context";
import { GrupareSubspecializare } from "../models/grupare-subspecializare.model";


export const GrupariSubspecializariPage = () => {
  const [grupariSubspecializari, setGrupariSubspecializari] = useState<GrupareSubspecializare[]>([]);
  const [specializari, setSpecializari] = useState<Specializare[]>([]);
  const [grupari, setGrupari] = useState<Grupare[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setValidationRules(x.validationRules);
      setGrupariSubspecializari(x.grupariSubspecializari);
      setSpecializari(x.specializari);
      setGrupari(x.grupari);
      setLoading(false);
    })

  }, [filters]);

  const GetRequiredData = async (): Promise<{ grupariSubspecializari: GrupareSubspecializare[], specializari: Specializare[], grupari: Grupare[], validationRules: ValidationRule[] }> => {
    let grupariSubspecializari = (await GetGrupariSubspecializariWithFilters(filters)).data;

    let specializari = (await GetSpecializari()).data;

    let grupari = (await GetGrupari()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('GrupareSubspecializare')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { grupariSubspecializari, specializari, grupari, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName), { filterName, filterValue }]);
  }

  const columns = useMemo((): Column<GrupareSubspecializare>[] => {
    var columns = [
      SelectColumn,
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        frozen: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'specializare',
        name: 'Specializare',
        renderEditCell: (p) => <RenderDropdown {...p} options={specializari.map((d) => ({ label: d.nume, value: d.id }))} items={specializari} handleChange={handleSpecializareDropdownChange} foreignKeyItemKey='specId' />,
        renderCell: (p) => (<>{p.row.specializare?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'specializare')?.filterValue || ''}
            applyFilter={val => applyFilter('specializare', val)}
          />)
      },
      {
        key: 'an',
        name: 'An',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'semestru',
        name: 'Semestru',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
    ];

    var maxGrupariLength = Math.max(...grupariSubspecializari.map(e => e.grupare?.length || 0));

    for (let idx = 1; idx <= maxGrupariLength + 1; idx++) {
      columns.push({
        key: `grupare${idx}`,
        name: `Grupare ${idx}`,
        renderEditCell: (p) => <RenderDropdown {...p} options={grupari.filter(g => g.specId == p.row.specId && g.an == p.row.an && g.semestru == p.row.semestru).map((d) => ({ label: d.nume, value: d.id }))} items={grupari} handleChange={handleGrupariDropdownChange} foreignKeyItemKey='grupare' />,
        renderCell: (p) => (<>{p.row.grupare[idx - 1]?.nume}</>)
      });
    }
console.log(columns);
    return columns;
  }, [grupari]);

  const handleGrupariDropdownChange = (row: Grupare, columnKey: string, selected: DropdownOption | null, items: Grupare[]) => {
    var idx = +columnKey.substring("grupare".length);
    if (selected) {
      const foundGrupare = items.find(i => i.id === selected.value);
      if (!foundGrupare) {
        row.grupare?.splice(idx - 1, 1);
      }
      else {
        row.grupare = row.grupare || [];
        row.grupare[idx - 1] = foundGrupare;
      }
    }
    else {
      row[columnKey] = null;
    }

    return row;
  }

  const handleSpecializareDropdownChange = (row: Grupare, columnKey: string, selected: DropdownOption | null, items: Specializare[]) => {
    if (selected) {
      const foundSpecializare = items.find(i => i.id === selected.value);
      if (!foundSpecializare) {
        row['specId'] = '';
        delete row['specializare'];
      }
      else {
        row['specId'] = foundSpecializare.id;
        row['specializare'] = foundSpecializare;
      }
    }
    else {
      row['specId'] = '';
      delete row['specializare'];
    }

    return row;
  }

  const rowsToString = (rows: GrupareSubspecializare[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = columns.map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length - 1; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];

        if (columnKey.includes("grupare") && row.grupare) {
          var grupareIdx = +columnKey.substring("grupare".length) - 1;
          var grupare = row['grupare'][grupareIdx];

          copiedString += grupare?.nume;
        }
        else {
          switch (columnKey) {
            case 'specializare':
              var specializare = row['specializare'];
              // var specializare = specializari.find(e => e.id == row.specializare?.id);
              copiedString += specializare?.nume;
              break;
            default:
              copiedString += row[columnKey as keyof GrupareSubspecializare];
              break;
          }
        }
        copiedString += '\t';
      }
      copiedString += '\r\n'
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): GrupareSubspecializare[] => {
    var rows: GrupareSubspecializare[] = [];
    const columnsKeys = columns.map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: GrupareSubspecializare = structuredClone(defaultObject) as GrupareSubspecializare;
      row.id = crypto.randomUUID();
      var grupareColumnsStartIdx = columns.findIndex(e => e.key == 'grupare1') - 1;

      for (let idx = 0; idx < grupareColumnsStartIdx; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'specializare':
            var specializare = specializari.find(e => e.nume == textRow[idx]);
            row['specId'] = specializare?.id ?? "";
            row['specializare'] = specializare;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }

      for (let idx = grupareColumnsStartIdx; idx < textRow.length; idx++) {
        var grupare = grupari.find(e => e.nume == textRow[idx]);
        if (grupare) {
          row['grupare']?.push(grupare);
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: GrupareSubspecializare[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: GrupareSubspecializare[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as GrupareSubspecializare;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as GrupareSubspecializare;
      }

      var grupareColumnsStartIdx = columns.findIndex(e => e.key == 'grupare1') - 1;
      for (let idx = columnIdx - 1; idx < grupareColumnsStartIdx; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'specializare':
            row['specId'] = transformedClipboardRows[clipboardRowIdx]['specializare']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      row['grupare'] = row['grupare']?.concat(transformedClipboardRows[clipboardRowIdx].grupare ?? []);

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  const deleteCell = (currentRows: GrupareSubspecializare[], rowIdx: number, columnKey: string): { rows: GrupareSubspecializare[], deleted: boolean } => {
    var deleted = true;

    if (columnKey.includes("grupare")) {
      currentRows[rowIdx]['grupare']?.splice(+columnKey.substring("grupare".length) - 1, 1);
    }
    else {
      switch (columnKey) {
        case 'specializare':
          currentRows[rowIdx]['specId'] = '';
          delete currentRows[rowIdx]['specializare'];
          break;
        default:
          deleted = false;
          break;
      }
    }

    return { rows: currentRows, deleted: deleted };
  }

  const updateColumns = (currentRows: GrupareSubspecializare[], columns: Column<GrupareSubspecializare>[]): Column<GrupareSubspecializare>[] => {
    const maxLength = Math.max(...currentRows.map(row => row.grupare?.length || 0));
    const maxColumnNumber = Math.max(...columns.filter(e => e.key.includes("grupare")).map(column => +column.key.substring("grupare".length)));

    if (maxLength < maxColumnNumber - 1) {
      columns.splice(columns.length - maxColumnNumber + maxLength + 1, maxColumnNumber - maxLength - 1);
    }
    else if (maxLength > maxColumnNumber - 1) {
      for (let idx = maxColumnNumber + 1; idx <= maxLength + 1; idx++) {
        columns.push({
          key: `grupare${idx}`,
          name: `Grupare ${idx}`,
          renderEditCell: (p) => <RenderDropdown {...p} options={grupari.filter(g => g.specId == p.row.specId && g.an == p.row.an && g.semestru == p.row.semestru).map((d) => ({ label: d.nume, value: d.id }))} items={grupari} handleChange={handleGrupariDropdownChange} foreignKeyItemKey='grupare' />,
          renderCell: (p) => (<>{p.row.grupare[idx - 1]?.nume}</>)
        });
      }
    }

    return columns;
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: GrupareSubspecializare): boolean => {
    if (row.nume !== '' || !!row.nume) return false;
    if (row.specId !== '' || !!row.specId) return false;
    if (row.grupare?.length != 0) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  let defaultObject: GrupareSubspecializare = { id: '', nume: '', an: '', semestru: '', specId: '', grupare: [], createdTime: new Date() };

  if (loading) {
    return <div>Loading...</div>;
  }

  // return <></>
  return <SheetPage
    requiredEditPermission={PermissionType.GrupareSubspecializareWrite}
    sheetColumns={columns}
    title={'Grupari Subspecializari'}
    hasEmptyProperties={e => hasEmptyProperties(e as GrupareSubspecializare)}
    initialEntries={grupariSubspecializari}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'grupare-subspecializare')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as GrupareSubspecializare[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    updateColumns={(currentRows, columns) => updateColumns(currentRows, columns)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}
