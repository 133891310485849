import { Button } from "@mui/material";
import { YearTable } from "./YearTable";
import AddIcon from '@mui/icons-material/Add';
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { useContext, useEffect, useMemo, useState } from "react";
import { YearPairTable } from "./YearPairTable";
import { PlanInvatamantTableData, PlanTableType } from "../../models/plan-invatamant-table-data.model";
import { YearTableData } from "../../models/year-table-data.model";
import { PlanTableContext } from "../../store/plan-table-context";



interface PlanProps {
  planData: PlanInvatamantTableData,
  materii,
  grupari,
  handleChange: (v, an, semestru, idx) => void
}

export const Plan = (props: PlanProps) => {

  const [planData, setPlanData] = useState<PlanInvatamantTableData>(props.planData);

  useEffect(() => {
    setPlanData(props.planData);
  }, [props.planData]);

  const planDataPairs = useMemo(() => {
    var dataPairs: {
      year1: YearTableData,
      year2?: YearTableData
    }[] = [];
    for (let idx = 0; idx < planData.years.length; idx += 2) {
      dataPairs[idx / 2] = { year1: planData.years[idx], year2: planData.years[idx + 1] };
    }

    dataPairs.forEach(pair => {
      var maxEntries = Math.max(...pair.year1.semesters.map(e => e.intrari.length), ...pair.year2?.semesters.map(e => e.intrari.length) || []);

      pair.year1.semesters.forEach(semester => {
        semester.intrari = semester.intrari.concat(Array(maxEntries - semester.intrari.length).fill({ tip: "Empty", intrare: {} as IPlanInvatamantEntryModel }))
      })

      pair.year2?.semesters.forEach(semester => {
        semester.intrari = semester.intrari.concat(Array(maxEntries - semester.intrari.length).fill({ tip: "Empty", intrare: {} as IPlanInvatamantEntryModel }))
      })

    })
    //adauga padding cu materii goale
    return dataPairs;
  }, [planData]);

  function handleAddButton(index: number): void {
    setPlanData(prevPlanData => {
      var newPlanData = { ...prevPlanData };

      newPlanData.years[2 * index].semesters.forEach(semester => {
        semester.intrari = semester.intrari.concat(Array(1).fill({ tip: "Empty", intrare: {} as IPlanInvatamantEntryModel }))
      })

      newPlanData.years[2 * index + 1]?.semesters.forEach(semester => {
        semester.intrari = semester.intrari.concat(Array(1).fill({ tip: "Empty", intrare: {} as IPlanInvatamantEntryModel }))
      })

      return newPlanData;
    })
  }

  return (
    <>
      {
        planDataPairs.length > 0 ?
          planDataPairs.map((pair, index) => {
            return <YearPairTable
              key={index}
              year1={pair.year1}
              year2={pair.year2}
              materii={props.materii}
              grupari={props.grupari}
              currentYear={2023 + 2 * index}//TODO replace hardcoded year with plan start
              index={index}
              handleAddButton={(idx) => handleAddButton(idx)}
              handleChange={props.handleChange}
            />
          })
          :
          <></>
      }
    </>
  );
}