import { Domeniu } from "../models/domeniu.model";
import { SheetPage } from "./SheetPage";
import { ValidationRule } from "../models/validation-rule.model";
import { GetSpecializariWithFilters, GetDomenii, GetValidationRules, HandleChanges } from "../api/ApiService";
import { useEffect, useState } from "react";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { Specializare } from "../models/specializare.model";
import { PermissionType } from "../store/permission-context";

export const SpecializariPage = () => {
  const [specializari, setSpecializari] = useState<Specializare[]>([]);
  const [domenii, setDomenii] = useState<Domeniu[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setSpecializari(x.specializari);
      setDomenii(x.domenii);
      setValidationRules(x.validationRules);
      setLoading(false);
    })
  }, [filters]);

  const GetRequiredData = async (): Promise<{ domenii: Domeniu[], specializari: Specializare[], validationRules: ValidationRule[] }> => {
    let specializari = (await GetSpecializariWithFilters(filters)).data;

    let domenii = (await GetDomenii()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('Specializare')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { domenii: domenii, specializari, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<Specializare>[] => {
    return [
      SelectColumn,
      {
        key: 'cod',
        name: 'Cod',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'domeniu',
        name: 'Domeniu',
        renderEditCell: (p) => {
          return <RenderDropdown {...p} options={domenii.map((d) => ({ label: d.numeDl, value: d.id }))} items={domenii} handleChange={handleDomeniuDropdownChange} foreignKeyItemKey='domId' />
        },
        renderCell: (p) => (<>{p.row.domeniu?.numeDl}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'domeniu')?.filterValue || ''}
            applyFilter={val => applyFilter('domeniu', val)}
          />)
      },
    ]
  };

  const handleDomeniuDropdownChange = (row: Specializare, columnKey: string, selected: DropdownOption | null, items: Domeniu[]) => {
    if (selected) {
      const found = items.find(i => i.id === selected.value);
      if (!found) {
        row['domId'] = '';
        delete row['domeniu'];
      }
      else {
        row['domId'] = found.id;
        row['domeniu'] = found;
      }
    }
    else {
      row['domId'] = '';
      delete row['domeniu'];
    }

    return row;
  }

  const rowsToString = (rows: Specializare[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {
          case 'domeniu':
            var domeniu = domenii.find(e => e.id == row.domeniu?.id);
            copiedString += domeniu?.numeDl;
            break;

          default:
            copiedString += row[columnKey as keyof Specializare];
            break;
        }
        copiedString += separator
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): Specializare[] => {
    var rows: Specializare[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: Specializare = structuredClone(defaultObject) as Specializare;
      row.id = crypto.randomUUID();
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'domeniu':
            var domeniu = domenii.find(e => e.numeDl == textRow[idx]);//TODO numeDl este nume domeniu licenta, aici trebuie sa depinda de ciclul de studiu
            row['domId'] = domeniu?.id ?? "";
            row['domeniu'] = domeniu;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: Specializare[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: Specializare[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as Specializare;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as Specializare;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'domeniu':
            row['domId'] = transformedClipboardRows[clipboardRowIdx]['domeniu']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: Specializare): boolean => {
    if (row.domId !== '' || !!row.domId) return false;
    if (row.nume !== '' || !!row.nume) return false;
    if (row.cod !== '' || !!row.cod) return false;
    if (row.nrCredite !== '' || !!row.nrCredite) return false;
    if (row.codIscedf !== '' || !!row.codIscedf) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: Specializare = { id: '', nume: '', domId: '', cod: '', nrCredite: '', codIscedf: '' };

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SheetPage
    requiredEditPermission={PermissionType.SpecializareWrite}
    sheetColumns={columns}
    title={'Specializari'}
    hasEmptyProperties={e => hasEmptyProperties(e as Specializare)}
    initialEntries={specializari}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'specializare')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as Specializare[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}