import { RenderHeaderCellProps } from "react-data-grid"
import { IModel } from "../../models/generic.model"
import { useEffect, useRef, useState } from "react"

interface HeaderCellRendererProps extends RenderHeaderCellProps<IModel> {
    applyFilter: (filter: string) => void,
    value: string
}

export const HeaderCellRenderer = (props: HeaderCellRendererProps) => {
    const [filter, setFilter] = useState<string>(props.value);
    const isInitialMount = useRef(true);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            if (isInitialMount.current) {
                isInitialMount.current = false;
            }
            else {
                props.applyFilter(filter);
            }


        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [filter])

    return (
        <div style={{ lineHeight: '35px' }}>
            <p>{props.column.name} 
                {
                    props.sortDirection === 'ASC' ? <span>&#9650;</span> : props.sortDirection === 'DESC' ? <span>&#9660;</span> : ''
                }
            </p>
            <div>
                <input
                    type="text"
                    value={filter}
                    className="block w-full p-2 upt-text-gray border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-700 focus:border-blue-700" 
                    placeholder={'Filtrează după ' + props.column.name.toString().toLowerCase()} 
                    onChange={(e) => setFilter(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        </div>
    )
}