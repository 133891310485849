import { useState } from "react";
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { IndicatorPlan, TipComparatie } from "../../models/indicator-plan.model";
import ComboBox from "../Combobox";
import { DetailBox } from "./DetailBox";

interface IndicatorPlanEntryProps {
    entry: IndicatorPlan,
    comboBoxOptions: { label: string, value: string }[],
    handleChange: (type: string, indicator: IndicatorPlan, value) => void
}





export const IndicatorPlanEntry = (props: IndicatorPlanEntryProps) => {

    const handleChange = (type: string, value) => {
        props.handleChange(type, props.entry, value);
        
    }

    const GetInputForIndice = (entry: IndicatorPlan) => {
        console.log(entry);
        switch (entry.tipComparatie) {
            case TipComparatie.ValoareExacta:

                return <div className="relative w-full flex">
                    <input type="number" placeholder="Valoare indice" value={entry.valoare}
                           onChange={v => handleChange('value', v.target.value)} step="0.1"
                           className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-6">
                        <span className="text-gray-500">{entry.unitateMasura}</span>
                    </div>
                </div>
            case TipComparatie.ValoareMinima:
                return <div className="relative w-full flex">
                    <input type="number" placeholder="Valoare indice" value={entry.valoare}
                           onChange={v => handleChange('value', v.target.value)} step="0.1"
                           className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-6">
                        <span className="text-gray-500">{entry.unitateMasura  + " (minim)"}</span>
                    </div>
                </div>
            case TipComparatie.ValoareMaxima:
                return <div className="relative w-full flex">
                    <input type="number" placeholder="Valoare indice" value={entry.valoare}
                           onChange={v => handleChange('value', v.target.value)} step="0.1"
                           className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-6">
                        <span className="text-gray-500">{entry.unitateMasura  + " (maxim)"}</span>
                    </div>
                </div>
            case TipComparatie.Interval:
                return <>
                    <div className="relative w-full flex">
                        <input type="number" placeholder="Valoare indice" value={entry.startInterval}
                               onChange={v => handleChange('value', v.target.value)} step="0.1"
                               className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                        <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-6">
                            <span className="text-gray-500">{entry.unitateMasura}</span>
                        </div>
                    </div>
                    <div className="relative w-full flex">
                        <input type="number" placeholder="Valoare indice" value={entry.finalInterval}
                               onChange={v => handleChange('value', v.target.value)} step="0.1"
                               className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                        <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-6">
                            <span className="text-gray-500">{entry.unitateMasura}</span>
                        </div>
                    </div>
                </>
        }
    }

    return (
        <section className="w-full">
            <div className="flex">
                <div className="w-full flex">
                    <ComboBox options={props.comboBoxOptions}
                              value={{label: (props.entry.nume || ""), value: (props.entry.indice.slug || "")}}
                              onChange={v => handleChange('combo', v?.value)}/>
                    <div className="flex w-4/12">
                        {
                            GetInputForIndice(props.entry)
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
