import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, selectedValues: readonly string[], theme: Theme) {
    return {
        fontWeight: selectedValues.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
        backgroundColor: selectedValues.includes(name) ? "#a5d6a7" : ""
    };
}

interface MultiselectDropdownProps {
    label: string,
    values: string[],
    selectedValues: string[],
    onChange: (selectedValues: string[]) => void,
}

export const MultiselectDropdown = (props: MultiselectDropdownProps) => {
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<typeof props.selectedValues>) => {
        const {
            target: { value },
        } = event;
        props.onChange(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
                <Select
                    multiple
                    value={props.selectedValues}
                    onChange={handleChange}
                    input={<OutlinedInput label={props.label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', gap: 0.5, overflow: "auto" }}>
                            {
                                selected.length === props.values.length ?
                                    <Chip key={"toate"} label={"Toate coloanele"}/> :

                                    selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {props.values.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, props.selectedValues, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}