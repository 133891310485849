import { RenderEditCellProps } from 'react-data-grid';
import { IModel } from '../../models/generic.model';
import ComboBox from '../Combobox';
import { useEffect, useState } from 'react';

export interface DropdownOption {
  label: string,
  value: string
}
interface DropdownRendererProps extends RenderEditCellProps<IModel> {
  items: IModel[],
  options: DropdownOption[],
  foreignKeyItemKey: string,
  noBlankOption: boolean,
  emptyOptionValue: any,
  handleChange: (row: IModel, columnKey: string, selected: DropdownOption | null, items: IModel[]) => IModel
}
export const RenderDropdown = (props: DropdownRendererProps) => {

  const defaultHandleChange = (row: IModel, columnKey: string, selected: DropdownOption | null, items: IModel[]) => {
    if (selected)
      row[columnKey] = items.find(i => i.id === selected.value);
    else {
      row[columnKey] = null;
    }

    return row;
  }

  const handleChange = (selected: DropdownOption | null) => {
    const handler = props.handleChange || defaultHandleChange;
    var newRow = handler({ ...props.row }, props.column.key, selected, props.items);

    props.onRowChange(newRow, true);
    console.log(props.emptyOptionValue);
  }

  return (
    <>
      {props.options.length > 0 ? (
        props.noBlankOption ?
          <ComboBox options={props.options} onChange={(v) => handleChange(v)} /> :
          <ComboBox options={[{ value: (props.emptyOptionValue || ''), label: '' }, ...props.options]} onChange={(v) => handleChange(v)} />
      )
        : <></>}
    </>
  );
}