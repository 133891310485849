import { useContext, useEffect, useState } from "react";
import { SheetPage } from "./SheetPage";
import { DisplayCategorieFormativaEnum, DisplayFormaEvaluareEnum, DisplayMaterieCategoryEnum, DisplayValidatedEnum, FormaEvaluareEnum, Materie, MaterieCategoryEnum } from "../models/materie.model";
import { GetDomenii, GetMateriiWithFilters, GetSpecializari, GetValidationRules, HandleChanges } from "../api/ApiService";
import { ValidationRule } from "../models/validation-rule.model";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { Domeniu } from "../models/domeniu.model";
import { Specializare } from "../models/specializare.model";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { ToastAlert } from "../components/ToastAlert";
import { camelToFlat, Comparator } from "../services/utils.service";
import { PermissionContext, PermissionType } from "../store/permission-context";

function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {

    case 'oreCurs':
    case 'oreSeminar':
    case 'oreLaborator':
    case 'oreProiect':
    case 'orePractica':
    case 'oreIndividuale':
    case 'nrCredite':
    case 'an':
    case 'semestru':
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };

    default:
      return (a, b) => {
        return (a[sortColumn] + '').localeCompare(b[sortColumn] + '');
      };
  }
}

export const MateriiPage = () => {
  const { hasPermission } = useContext(PermissionContext);

  const [materii, setMaterii] = useState<Materie[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [specializari, setSpecializari] = useState<Specializare[]>([]);
  const [domenii, setDomenii] = useState<Domeniu[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    GetRequiredData().then(x => {
      setValidationRules(x.validationRules);
      setMaterii(x.materii);
      setSpecializari(x.specializari);
      setDomenii(x.domenii);
      setLoading(false);
    })

  }, [filters]);

  const GetRequiredData = async (): Promise<{ materii: Materie[], specializari: Specializare[], domenii: Domeniu[], validationRules: ValidationRule[] }> => {
    let materii = (await GetMateriiWithFilters(filters)).data;

    let specializari = (await GetSpecializari()).data;

    let domenii = (await GetDomenii()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('Materie')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { materii, specializari, domenii, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<Materie>[] => {
    return [
      SelectColumn,
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        frozen: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'validated',
        name: 'Validat',
        editable: hasPermission(PermissionType.MaterieValidate),
        renderEditCell: (p) => <RenderDropdown {...p} options={Object.keys(DisplayValidatedEnum).map((key) => ({ label: DisplayValidatedEnum[key], value: key }))} items={[]} noBlankOption={true} handleChange={handleNonNullableDropdownChange} />,
        renderCell: (p) => (<>{DisplayValidatedEnum[p.row.validated]}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'validat')?.filterValue || ''}
            applyFilter={val => applyFilter('validat', val)}
          />)
      },
      {
        key: 'cod',
        name: 'Cod',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'domeniu',
        name: 'Domeniu',
        renderEditCell: (p) => <RenderDropdown {...p} options={domenii.map((d) => ({ label: d.numeDl, value: d.id }))} handleChange={handleDomeniuDropdownChange} items={domenii} foreignKeyItemKey='domId' />,
        renderCell: (p) => (<>{p.row.domeniu?.numeDl}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'domeniu')?.filterValue || ''}
            applyFilter={val => applyFilter('domeniu', val)}
          />)
      },
      {
        key: 'specializare',
        name: 'Specializare',
        renderEditCell: (p) => <RenderDropdown {...p} options={specializari.map((d) => ({ label: d.nume, value: d.id }))} handleChange={handleSpecializareDropdownChange} items={specializari} foreignKeyItemKey='specId' />,
        renderCell: (p) => (<>{p.row.specializare?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'specializare')?.filterValue || ''}
            applyFilter={val => applyFilter('specializare', val)}
          />)
      },
      {
        key: 'an',
        name: 'An',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'semestru',
        name: 'Semestru',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'category',
        name: 'Categorie',
        resizable: true,
        renderEditCell: (p) => <RenderDropdown {...p} options={Object.keys(DisplayMaterieCategoryEnum).map((key) => ({ label: DisplayMaterieCategoryEnum[key], value: key }))} emptyOptionValue={'0'} items={[]} handleChange={handleNullableDropdownChange} />,
        renderCell: (p) => (<>{DisplayMaterieCategoryEnum[p.row.category]}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'category')?.filterValue || ''}
            applyFilter={val => applyFilter('category', val)}
          />)
      },
      {
        key: 'oreCurs',
        name: 'Ore curs',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'oreSeminar',
        name: 'Ore seminar',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'oreLaborator',
        name: 'Ore laborator',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'oreProiect',
        name: 'Ore proiect',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'orePractica',
        name: 'Ore practica',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'oreIndividuale',
        name: 'Ore individuale',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'nrCredite',
        name: 'Nr credite',
        renderEditCell: textEditor,
        resizable: true,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'categorieFormativa',
        name: 'Categorie formativa',
        renderEditCell: (p) => <RenderDropdown {...p} options={Object.keys(DisplayCategorieFormativaEnum).map((key) => ({ label: DisplayCategorieFormativaEnum[key], value: key }))} items={[]} handleChange={handleNonNullableDropdownChange} />,
        renderCell: (p) => (<>{DisplayCategorieFormativaEnum[p.row.categorieFormativa]}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'formaEvaluare',
        name: 'Forma evaluare',
        renderEditCell: (p) => <RenderDropdown {...p} options={Object.keys(DisplayFormaEvaluareEnum).map((key) => ({ label: DisplayFormaEvaluareEnum[key], value: key }))} items={[]} handleChange={handleNonNullableDropdownChange} />,
        renderCell: (p) => (<>{DisplayFormaEvaluareEnum[p.row.formaEvaluare]}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ''}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      }
    ]
  };

  const handleDomeniuDropdownChange = (row: Materie, columnKey: string, selected: DropdownOption | null, items: Domeniu[]) => {
    if (selected) {
      const foundDomeniu = items.find(i => i.id === selected.value);
      if (!foundDomeniu) {
        row['domId'] = '';
        delete row['domeniu'];
      }
      else {
        row['domId'] = foundDomeniu.id;
        row['domeniu'] = foundDomeniu;
      }
    }
    else {
      row['domId'] = '';
      delete row['domeniu'];
    }

    return row;
  }

  const handleNonNullableDropdownChange = (row: Materie, columnKey: string, selected: DropdownOption | null, items: []) => {
    if (selected) {
      row[columnKey] = selected.value;
    }
    return row;
  }

  const handleNullableDropdownChange = (row: Materie, columnKey: string, selected: DropdownOption | null, items: []) => {
    if (selected) {
      row[columnKey] = selected.value;
    }
    else {
      row[columnKey] = 0;
    }
    return row;
  }

  const handleSpecializareDropdownChange = (row: Materie, columnKey: string, selected: DropdownOption | null, items: Specializare[]) => {
    if (selected) {
      const foundSpecializare = items.find(i => i.id === selected.value);
      if (!foundSpecializare) {
        row['specId'] = '';
        delete row['specializare'];
      }
      else {
        row['specId'] = foundSpecializare.id;
        row['specializare'] = foundSpecializare;
      }
    }
    else {
      row['specId'] = '';
      delete row['specializare'];
    }

    return row;
  }

  const rowsToString = (rows: Materie[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {
          case 'specializare':
            var specializare = specializari.find(e => e.id == row.specializare?.id);
            copiedString += specializare?.nume;
            break;
          case 'domeniu':
            var domeniu = domenii.find(e => e.id == row.domeniu?.id);
            copiedString += domeniu?.numeDl;
            break;
          default:
            copiedString += row[columnKey as keyof Materie];
            break;
        }
        copiedString += separator;
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): Materie[] => {
    var rows: Materie[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: Materie = structuredClone(defaultObject) as Materie;

      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'specializare':
            var specializare = specializari.find(e => e.nume == textRow[idx]);
            row['specId'] = specializare?.id ?? "";
            row['specializare'] = specializare;
            break;
          case 'domeniu':
            var domeniu = domenii.find(e => e.numeDl == textRow[idx]);
            row['domId'] = domeniu?.id ?? "";
            row['domeniu'] = domeniu;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: Materie[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: Materie[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as Materie;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as Materie;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'domeniu':
            row['domId'] = transformedClipboardRows[clipboardRowIdx]['domeniu']?.id || '';
            break;
          case 'specializare':
            row['specId'] = transformedClipboardRows[clipboardRowIdx]['specializare']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  const deleteCell = (currentRows: Materie[], rowIdx: number, columnKey: string): { rows: Materie[], deleted: boolean } => {
    var deleted = true;

    switch (columnKey) {
      case 'domeniu':
        currentRows[rowIdx]['domId'] = '';
        delete currentRows[rowIdx]['domeniu'];
        break;
      case 'specializare':
        currentRows[rowIdx]['specId'] = '';
        delete currentRows[rowIdx]['specializare'];
        break;
      default:
        deleted = false;
        break;
    }

    return { rows: currentRows, deleted: deleted };
  }



  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: Materie): boolean => {
    if (row.domId !== '' || !!row.domId) return false;
    if (row.prefix !== '' || !!row.prefix) return false;
    if (row.cod !== '' || !!row.cod) return false;
    if (row.nume !== '' || !!row.nume) return false;
    if (row.specId !== '' || !!row.specId) return false;
    if (row.oreCurs !== '') return false;
    if (row.an !== '') return false;
    if (row.semestru !== '') return false;
    if (row.oreSeminar !== '') return false;
    if (row.oreLaborator !== '') return false;
    if (row.oreProiect !== '') return false;
    if (row.orePractica !== '') return false;
    if (row.oreIndividuale !== '') return false;
    if (row.nrCredite !== '') return false;
    if (row.formaEvaluare !== '' || !!row.formaEvaluare) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: Materie = {
    id: '',
    domId: '',
    cod: '',
    prefix: '',
    nume: '',
    an: '',
    semestru: '',
    specId: '',
    oreCurs: '',
    oreSeminar: '',
    oreLaborator: '',
    oreProiect: '',
    orePractica: '',
    oreIndividuale: '',
    nrCredite: '',
    formaEvaluare: '',
    categorieFormativa: '',
    createdTime: new Date(),

    validated: ''
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // return <></>
  return <SheetPage
    requiredEditPermission={PermissionType.MaterieWrite}
    sheetColumns={columns}
    title={'Materii'}
    hasEmptyProperties={e => hasEmptyProperties(e as Materie)}
    initialEntries={materii}
    validationRules={validationRules}
    validated={materii.map(e => e.validated)}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'materie')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as Materie[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    getComparator={(sortColumn) => getComparator(sortColumn)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
  // return <div className="w-100 pt-20 flex flex-col min-h-screen">
  //   <div className="w-100 flex justify-between align-center my-5">
  //     <h1 className="text-5xl font-semibold upt-text-dark">Materii</h1>
  //   </div>
  //   {/* {
  //     sheetData.errors.length > 0 ? <div className="bg-rose-100 border-t-4 rounded-b text-rose-900 px-4 py-3 shadow-md mb-4" role="alert">
  //       <div className="flex">
  //         <div className="py-1"><svg className="fill-current h-6 w-6 text-rose-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
  //         <div>
  //           <p className="font-bold">Au fost găsite erori în următoarele schimbări</p>
  //           {sheetData.errors.map(e => <p className="text-sm">Coloana {camelToFlat(e.rule.fieldName)}, rândul {sheetData.rows.findIndex(r => r.id === e.rowId) + 1}, cu valoarea "{e.value}" are eroarea: {e.rule.errorMessage}.</p>)}
  //         </div>
  //       </div>
  //     </div> : <></>
  //   } */}

  //   <SheetTable
  //     sheetColumns={columns}
  //     title={'Materii'}
  //     hasEmptyProperties={e => hasEmptyProperties(e as Materie)}
  //     initialEntries={materii}
  //     validationRules={validationRules}
  //     validated={materii.map(e => e.validated)}
  //     onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'materie')}
  //     rowsToString={(rows, columnsOrder) => rowsToString(rows as Materie[], columnsOrder)}
  //     stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
  //     addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
  //     deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
  //     defaultObject={defaultObject}
  //     refreshTable={refreshTable}
  //   />
  //   <div className="flex w-1o0 justify-end py-10">
  //     <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 mr-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
  //       Salveaza
  //     </button>
  //     <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
  //       Salveaza
  //     </button>
  //   </div>
  //   {/* {toastInfo.show ? <ToastAlert message={toastInfo.message} type={toastInfo.type} onClose={() => setToastInfo({ ...toastInfo, show: false })} /> : <></>} */}
  // </div>
}
