import { useContext, useEffect, useState } from "react";
import { GetPlanuriInvatamant } from "../api/ApiService";
import { Materie } from "../models/materie.model";
import { PlanInvatamant } from "../models/plan-invatamant.model";
import { NavLink, useParams } from "react-router-dom";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { SheetPage } from "./SheetPage";
import { AddPlanModal } from "../components/planuri-invatamant/AddPlanModal";
import { GenericReadonlyTable, GenericTableProps } from "../components/GenericReadonlyTable";
import PlanContextProvider from "../store/plan-context";
import { PermissionContext, PermissionType } from "../store/permission-context";


export const PlanuriInvatamantPage = () => {
  const { hasPermission } = useContext(PermissionContext);

  // const [sheetData, setSheetData] = useState<{ rows: IModel[], errors: ValidatedCellError[], modifiedEntriesIds: string[], deletedEntriesIds: string[] }>({ rows: [], errors: [], modifiedEntriesIds: [], deletedEntriesIds: [], });
  const [planuriInvatamant, setPlanuriInvatamant] = useState<PlanInvatamant[]>([]);
  const [loading, setLoading] = useState(true);
  const [addPlanModalOpen, setAddPlanModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    GetRequiredData().then(x => {
      setPlanuriInvatamant(x.planuriInvatamant);
      setLoading(false);
    })

  }, [refetch]);

  const GetRequiredData = async (): Promise<{ planuriInvatamant: PlanInvatamant[] }> => {
    let planuriInvatamant = (await GetPlanuriInvatamant()).data;

    // let validationRules = [];
    // try {
    //   validationRules = (await GetValidationRules('Grupare')).data;
    // } catch (error) {
    //   console.log('Validation rules not found');
    // }

    return { planuriInvatamant }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const planuriTable: GenericTableProps = {
    headers: [
      { label: 'Nume', key: 'nume' },
      { label: 'Domeniu', key: 'numeDomeniu' },
      { label: 'Specializare', key: 'numeSpecializare' },
      { label: 'An început', key: 'anInceput' },
      { label: 'An final', key: 'anFinal' },
      { label: 'Validat', key: 'validated' },

    ],
    data: planuriInvatamant.map(p => { return { ...p, numeDomeniu: p.domeniu?.numeDl, numeSpecializare: p.specializare?.nume } })
  }

  return <PlanContextProvider>

    <div className="flex justify-between py-10" style={{ marginTop: "60px" }}>
      <h1 className="text-5xl font-semibold upt-text-dark">Planuri de învațământ</h1>
      {hasPermission(PermissionType.PlanInvatamantWrite) && <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => setAddPlanModalOpen(true)}>
        Adaugă plan de învățământ
      </button>}
    </div>
    {hasPermission(PermissionType.PlanInvatamantWrite) && <AddPlanModal addPlanModalOpen={addPlanModalOpen} onClose={() => setAddPlanModalOpen(false)}  onSuccessSave={() => setRefetch(redo => !redo)}/>}

    <div className="flex align-center justify-center w-full">
      <GenericReadonlyTable headers={planuriTable.headers} data={planuriTable.data} showViewButton={true} viewButtonLabel="Vizualizează planul" />
    </div>
  </PlanContextProvider >
}
